<template>
  <select
    :disabled="disabled"
    class="dropdown-label currency-selector"
    :class="customClasses"
    :required="required"
    :value="modelValue"
    @keydown="keydownFunc"
    @blur="blurFunc"
    @change="changeFunc"
  >
    <option
      hidden
      selected
      value=""
    >
      {{ selectPlaceholder }}
    </option>
    <option
      v-for="(optionValue, key) in options"
      :key="key"
      :selected="modelValue === key"
      :value="key"
      v-text="optionValue"
    />
  </select>
</template>

<script>
export default {
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectPlaceholder: {
      type: String,
      default: 'Select Value',
    },
    options: {
      required: true,
      type: [Array, Object],
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default() { return ''; },
    },
  },
  emits: ['update:modelValue', 'change', 'keydown', 'blur'],
  methods: {
    blurFunc(event) {
      this.$emit('blur', event);
    },
    keydownFunc(event) {
      this.$emit('keydown', event);
    },
    changeFunc(event) {
      this.inputFunc(event);
      this.$emit('change', event);
    },
    inputFunc(event) {
      this.$log.info('input event value:', event.target.value);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
