<template>
  <div
    v-if="!enableUXRefresh"
    class="filter-toggle"
    :class="{...customClasses, 'filter-toggle-active': modelValue}"
    @click="onFilterToggled"
  >
    {{ currentLabel }}
    <div
      v-show="badgeText !== ''"
      class="badge-text-container"
    >
      <div class="badge-text">
        {{ badgeText }}
      </div>
    </div>
  </div>
  <ActionButtonRefresh
    v-else-if="enableUXRefresh && badgeText"
    :variant="modelValue ? 'SECONDARY' : 'TERTIARY'"
    :text="currentLabel"
    :badge-content="badgeText !== '' ? badgeText : null"
    @on-click="onFilterToggled"
  />
</template>
<script>
import ActionButtonRefresh from '@/components/general/buttons/ActionButtonRefresh.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  components: {
    ActionButtonRefresh,
  },
  props: {
    badgeText: {
      type: String,
      default: '',
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    labelActive: {
      type: String,
      required: true,
    },
    labelInactive: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    currentLabel() {
      return this.modelValue ? this.labelActive : this.labelInactive;
    },
  },
  methods: {
    onFilterToggled() {
      this.$log.info('onFilterToggled, new value:', !this.modelValue);
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>
