<template>
  <div
    v-if="currentEntity && hasEntityInformation()"
    class="wrap-collapsible"
    :class="{'entity-detail-v2': v2}"
  >
    <input
      :id="uuid"
      class="toggle"
      type="checkbox"
    >
    <label
      v-if="!v2"
      :for="uuid"
      class="lbl-toggle"
    >
      Show detail..
    </label>
    <label
      v-else
      :for="uuid"
      class="lbl-toggle"
    >
      Show entity details..
    </label>
    <div class="collapsible-content">
      <div class="content-inner">
        <p> Entity information: </p>
        <ul
          v-if="currentEntity.fins"
          style="margin-left: 20px; list-style-type: circle"
        >
          <li v-if="currentEntity.clientIdentifier && !clientIdentifierInFins()">
            Default : {{ currentEntity.clientIdentifier }}
          </li>
          <li
            v-for="(fin, idx) in currentEntity.fins"
            :key="idx"
          >
            {{ fin.display }} <a v-if="fin.clientIdentifier"> ID: {{ fin.clientIdentifier }} </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    v-else
    class="entity-detail-v2"
  >
    <p
      class="missing-info-placeholder entity-detail-v2-no-info"
    >
      No entity information to show
    </p>
  </div>
</template>
<script>
let uuid = 0;

export default {
  props: {
    currentEntity: {
      type: [Object, String],
      required: true,
    },
    v2: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    // For creating unique toggles.
    this.uuid = uuid.toString();
    uuid += 1;
  },
  methods: {
    hasEntityInformation() {
      return this.currentEntity.clientIdentifier !== null || this.currentEntity.fins.length !== 0;
    },
    clientIdentifierInFins() {
      const { fins } = this.currentEntity;
      for (let i = 0; i < fins.length; i++) {
        if (fins[i].clientIdentifier === this.currentEntity.clientIdentifier) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.entity-detail-v2 {
  font-size: 14px;

  &.wrap-collapsible {
    margin-top: 0 !important;
    background: #f7fafa;
    padding: 12px;
    border-radius: 8px;

    .content-inner {
      padding-top: 0;
    }
  }

  p {
    margin: 0;
    font-size: 14px !important;
    color: #1A2C2D;
  }

  ul {
    margin: 0;
  }

  .lbl-toggle {
    color: #1A2C2D;

    &::before {
      margin-right: 2px;
      margin-top: 3px;
    }
  }

  .entity-detail-v2-no-info {
    margin: 0;
    background: #f7fafa;
    padding: 12px;
    font-style: normal;
    border-radius: 8px;
    color: #707A81;
  }
}
</style>
