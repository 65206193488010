<template>
  <table v-if="!enableInfiniteScroll || (enableInfiniteScroll && metricsLoaded)">
    <metric-table-column-headers
      :column-headers="columnHeaders"
      :column-header-options="columnHeaderOptions"
      :column-verification-flags="columnVerificationFlags"
      :selected-headers="selectedHeaders"
      :show-column-indices="showColumnIndices"
      @headerSelected="onHeaderSelected"
      @verifyHeaderToggled="onToggleHeaderVerified"
      @headerCurrencyChanged="onHeaderCurrencyChanged"
      @headerAdjustScale="onHeaderAdjustScale"
      @headerNumericFormatChanged="onHeaderNumericFormatChanged"
    />
    <empty-metric-table-row
      v-if="enableInfiniteScroll && (metricsLoaded && rows.length === 0 && numColumns !== 0)"
      @addNewRow="onAddNewRowAt(0)"
    />
    <metric-table-data-cells
      v-else-if="(!enableInfiniteScroll) || (enableInfiniteScroll && metricsLoaded && rows.length > 0)"
      :calculated-totals="calculatedTotals"
      :column-headers="columnHeaders"
      :has-entity-name-row-headers="hasEntityNameRowHeaders"
      :rows="rows"
      :metrics="metrics"
      :num-visible-columns="numVisibleColumns"
      :num-visible-rows="numVisibleRows"
      :selected-cells="selectedCells"
      :selected-headers="selectedHeaders"
      :selected-row-headers="selectedRowHeaders"
      :show-calculated-totals="showCalculatedTotals"
      :show-column-indices="showColumnIndices"
      :num-columns="columnHeaders.length"
      :show-row-indices="showRowIndices"
      :is-repeating="isRepeating"
      :has-calculated-totals="hasCalculatedTotals"
      @cellSelected="onCellSelected"
      @rowHeaderSelected="onRowHeaderSelected"
      @toggleCellVerified="onToggleCellVerified"
      @cellValueUpdated="onCellValueUpdated"
      @cellCurrencyValueUpdated="onCellCurrencyValueUpdated"
      @cellAkasUpdated="onCellAkasUpdated"
      @cellSelectedEntityUpdated="onCellSelectedEntityUpdated"
      @addNewRowAt="onAddNewRowAt"
      @deleteRowAt="onDeleteRowAt"
      @renameEntityAt="onRenameEntityAt"
      @removeEntity="onRemoveEntity"
      @setLoading="onSetLoading"
    />
  </table>
</template>
<script>
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import MetricTableColumnHeaders from '@/components/verify/tableLayout/MetricTableColumnHeaders.vue';
import MetricTableDataCells from '@/components/verify/tableLayout/MetricTableDataCells.vue';
import EmptyMetricTableRow from '@/components/verify/tableLayout/EmptyMetricTableRow.vue';

export default {
  components: { MetricTableColumnHeaders, MetricTableDataCells, EmptyMetricTableRow },
  props: {
    metricTableIsLoading: {
      type: Boolean,
      required: true,
    },
    calculatedTotals: {
      type: Array,
      required: true,
    },
    columnHeaderOptions: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Array,
      required: true,
    },
    columnVerificationFlags: {
      type: Array,
      required: true,
    },
    hasEntityNameRowHeaders: {
      type: Boolean,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },
    numVisibleColumns: {
      type: Number,
      required: true,
    },
    numVisibleRows: {
      type: Number,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    showRowIndices: {
      type: Array,
      required: true,
    },
    selectedCells: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
    selectedRowHeaders: {
      type: Array,
      required: true,
    },
    showCalculatedTotals: {
      type: Boolean,
      required: true,
    },
    showColumnIndices: {
      type: Array,
      required: true,
    },
    tableIdx: {
      type: Number,
      required: true,
    },
    isRepeating: {
      type: Boolean,
      required: true,
    },
    hasCalculatedTotals: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'addNewRowAt',
    'cellAkasUpdated',
    'cellCurrencyValueUpdated',
    'cellSelected',
    'cellValueUpdated',
    'deleteRowAt',
    'headerCurrencyChanged',
    'headerAdjustScale',
    'headerNumericFormatChanged',
    'headerSelected',
    'rowHeaderSelected',
    'toggleCellVerified',
    'verifyHeaderToggled',
    'cellSelectedEntityUpdated',
    'renameEntityAt',
    'removeEntity',
    'setLoading',
  ],
  data() {
    return {
      metricsLoaded: false,
    };
  },
  computed: {
    enableInfiniteScroll() {
      return FEATURE_FLAGS.METRICS_INFINITE_SCROLL;
    },
  },
  watch: {
    metricTableIsLoading: {
      immediate: true,
      // TODO: Optimize
      handler(newVal) {
        if (newVal === false) {
          this.metricsLoaded = true;
        }
      },
    },
  },
  methods: {
    onHeaderSelected(headerIdx) {
      this.$emit('headerSelected', headerIdx);
    },
    onToggleHeaderVerified(headerIdx) {
      this.$emit('verifyHeaderToggled', headerIdx);
    },
    onHeaderCurrencyChanged(headerIdx) {
      this.$emit('headerCurrencyChanged', headerIdx);
    },
    onHeaderAdjustScale(headerIdx, scale) {
      this.$emit('headerAdjustScale', headerIdx, scale);
    },
    onHeaderNumericFormatChanged(headerIdx) {
      this.$emit('headerNumericFormatChanged', headerIdx);
    },
    onCellSelected(rowIdx, colIdx) {
      this.$emit('cellSelected', rowIdx, colIdx);
    },
    onRowHeaderSelected(rowIdx, colIdx) {
      this.$emit('rowHeaderSelected', rowIdx, colIdx);
    },
    onToggleCellVerified(rowIdx, colIdx) {
      this.$emit('toggleCellVerified', rowIdx, colIdx);
    },
    onCellValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellValueUpdated', rowIdx, colIdx, newValue);
    },
    onCellCurrencyValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellCurrencyValueUpdated', rowIdx, colIdx, newValue, true, false);
    },
    onCellAkasUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellAkasUpdated', rowIdx, colIdx, newValue);
    },
    onCellSelectedEntityUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellSelectedEntityUpdated', rowIdx, colIdx, newValue);
    },
    onAddNewRowAt(rowIdx) {
      this.$emit('addNewRowAt', rowIdx);
    },
    onDeleteRowAt(rowIdx) {
      this.$emit('deleteRowAt', rowIdx);
    },
    onRenameEntityAt(rowIdx, colIdx, rowValue, metricFin, metricValidators) {
      this.$emit('renameEntityAt', rowIdx, colIdx, rowValue, metricFin, metricValidators);
    },
    onRemoveEntity(colIdx) {
      this.$emit('removeEntity', colIdx);
    },
    onSetLoading(isLoading) {
      this.$emit('setLoading', isLoading);
    },
  },
};
</script>
