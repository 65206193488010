<template>
  <td
    class="table-cell"
    :class="Object.assign(
      customClasses,
      {
        'is-selected': isCellSelected,
        'is-highlighted': isCellHighlighted,
        'table-cell-dropdown': validatorOptions
      }
    )"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <div
          v-show="(enableUXRefresh ? true : !isVerified)"
          v-if="showConfidenceBars"
          class="left-of-input"
        >
          <confidence-bars :confidence="confidence" />
        </div>
        <vue-multi-select
          v-if="validatorOptions"
          :model-value="value"
          :options="validatorOptions"
          :class="{'multiselect-v2': enableUXRefresh}"
          placeholder="Not Reported"
          :disabled="readOnlyMode"
          @update:model-value="updateOptions"
          @open="onCellSelected"
        />
        <input
          v-else
          type="text"
          class="cell-content numeric-cell-content"
          placeholder="Not Reported"
          :value="value"
          :readonly="readOnlyMode"
          @input="updateValue($event.target.value)"
          @blur="onValueInputBlurred"
        >
        <alert-circle-icon
          v-if="validationError"
          v-tooltip="`${validationError}`"
          size="1.2x"
          stroke="red"
        />
        <metric-verify-toggle
          v-if="enableUXRefresh"
          :v2="true"
          :show-toggle="isCellSelected"
          :is-verified="isVerified"
          @toggleCellVerified="onVerifyToggled"
        />
        <div
          v-if="enableUXRefresh"
          class="right-of-input metric-info-icon"
        />
      </div>
      <metric-verify-toggle
        v-if="!enableUXRefresh"
        :show-toggle="isCellSelected"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </td>
</template>
<script>
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import VueMultiSelect from 'vue-multiselect';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import useUpdateCellValue from '@/hooks/useNumericUpdateCellValue';
import ConfidenceBars from '@/components/general/ConfidenceBars.vue';
import { getNumericValidatorOptions } from '@/store/helpers/metrics/metricValidators';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { inject } from 'vue';

export default {
  components: {
    ConfidenceBars, MetricVerifyToggle, VueMultiSelect, AlertCircleIcon,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      validator: (propValue) => Number.isFinite(propValue) || propValue === null,
      required: true,
    },
    confidence: {
      type: Number,
      default: null,
    },
    numericType: {
      type: String,
      default: 'NUMBER',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'update:modelValue'],
  setup(props, context) {
    const showConfidenceBars = inject('showConfidenceIndicators', true);
    const {
      value, updateValue, onValueInputBlurred, updateOptions, validationError,
    } = useUpdateCellValue(props, context);
    const enableUXRefresh = FEATURE_FLAGS.ENABLE_UX_REFRESH;
    return {
      value, updateValue, onValueInputBlurred, updateOptions, validationError, enableUXRefresh, showConfidenceBars,
    };
  },
  computed: {
    validatorOptions() {
      return getNumericValidatorOptions(this.validators);
    },
  },
  methods: {
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
  },
};
</script>
