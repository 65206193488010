import { logger } from '@/store/logger';

/**
 * Scroll to metric in table so the user can see it.
 * The formulas are based on ignoring the column headers.
 * Sometimes there is also a calculated totals column to ignore.
 * Metrics are read from left to right, top to bottom.
 *
 * Key: (rowIdx, colIdx)
 *      CH = Column Header
 *  ┌─────┬─────┬─────┐
 *  │ CH  │ CH  │ CH  │
 *  ├─────┼─────┼─────┤
 *  │(0,0)│(0,1)│(0,2)│
 *  ├─────┼─────┼─────┤
 *  │(1,0)│(1,1)│(1,2)│
 *  └─────┴─────┴─────┘
 *
 * E.g: for (1,1), the index will be 4.
 *      for (0,2), the index will be 2.
 *
 * 'scrollIntoView()' docs: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 */
const jumpToMetric = (rowIdx, colIdx, colHeadersCount, rowHeadersCount, isTransposed, showCalculatedTotals) => {
  logger.debug(`jumpToMetric (${rowIdx}, ${colIdx})`);
  let index;
  if (isTransposed) {
    index = colIdx * (rowHeadersCount + 1 + showCalculatedTotals) + rowIdx + 1;
  } else {
    index = rowIdx * colHeadersCount + colIdx + colHeadersCount;
  }
  const itemToView = document.querySelectorAll('.table-cell-container')[index];
  itemToView.scrollIntoView({ block: 'center', inline: 'center' });
};

/**
 * Find the filter group the user view needs to change to.
 * @returns {int} index of the filter group to change to.
 */
const getJumpToFilterGroupIndex = (columnHeaders, colIdx, filterOptions) => {
  const newFilter = columnHeaders[colIdx].filterGroup;
  logger.debug('getJumpFilterGroupIndex for: ', newFilter);
  return filterOptions.indexOf(newFilter);
};

export {
  jumpToMetric,
  getJumpToFilterGroupIndex,
};
