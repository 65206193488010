<template>
  <div
    class="verify-document"
    :class="{'verification-page-v2': enableUXRefresh}"
  >
    <nav class="verify-document-nav">
      <verify-nav
        v-if="document"
        :custom-classes="{ 'verify-document-nav-row-1': true }"
        :auto-save-status="autoSaveStatus"
        :confirm-document-status="confirmDocumentStatus"
        :attempting-document-status-update="attemptingDocumentStatusUpdate"
        :can-redo="canRedo"
        :is-repeating="tableForCurrentBreadcrumb.isRepeating"
        :file-name="document.fileName"
        :extraction-progress-stage-name="document.extractionProgressStageName"
        :document-request-id="document.documentRequestId"
        :document-type-id="document.documentTypeDisplay"
        :can-verify-row="canVerifyRow"
        :can-verify-column="canVerifyColumn"
        @close="onClosePressed"
        @verify="onConfirmTriggered"
        @updateDocumentStatus="onUpdateDocumentStatus"
        @verifyMenuActionTriggered="onVerifyMenuActionTriggered"
        @updateDocumentType="onUpdateDocumentType"
        @splitDocument="onSplitDocumentTriggered"
      />
      <ReadOnlyBanner v-if="document && readOnlyMode" />
      <breadcrumb-nav
        v-if="!enableUXRefresh"
        :custom-classes="{'verify-document-nav-row-2': true}"
        :current-breadcrumb-index="breadcrumbIndex"
        :breadcrumbs="breadcrumbs"
        :document-request-id="documentRequestId"
        :document-is-popped-out="!showViewerPane"
        @popOutToggled="onPopoutToggled"
      />
    </nav>
    <div class="content-container">
      <splitpanes
        @resize="isResizingPanes = true"
        @resized="isResizingPanes = false"
      >
        <pane class="metric-section-container">
          <breadcrumb-nav
            v-if="enableUXRefresh && tableForCurrentBreadcrumb.table !== undefined"
            :custom-classes="{'verify-document-nav-row-2': true}"
            :current-breadcrumb-index="breadcrumbIndex"
            :breadcrumbs="breadcrumbs"
            :document-request-id="documentRequestId"
            :document-is-popped-out="!showViewerPane"
            @popOutToggled="onPopoutToggled"
          />
          <keep-alive>
            <metric-section
              v-if="tableForCurrentBreadcrumb.table !== undefined"
              :key="tableForCurrentBreadcrumb.tableIndex"
              :document-request-id="documentRequestId"
              :initial-table="tableForCurrentBreadcrumb.table"
              :table-idx="tableForCurrentBreadcrumb.tableIndex"
              :breadcrumb-idx="breadcrumbIndex"
              :is-table-repeating="tableForCurrentBreadcrumb.isRepeating"
              :verify-menu-action-triggers="verifyMenuActionTriggers"
              :has-calculated-totals="tableForCurrentBreadcrumb.hasCalculatedTotals"
              :default-transposed="defaultTranspose"
            />
          </keep-alive>
        </pane>
        <pane v-if="showViewerPane">
          <!-- An invisible overlay is applied when the pane is being resized. This is because the document
            is embedded in an iframe, where mouse events are not captured and cause issues during resizing. -->
          <div
            v-if="isResizingPanes"
            class="pane-invisible-overlay"
          />
          <pspdfkit-wrapper-viewer
            :document-request-id="documentRequestId"
            :local-document-path="localDocumentPath"
          />
        </pane>
      </splitpanes>
    </div>
  </div>
  <update-document-type-modal
    v-if="selectedDocumentType"
    :show="showUpdateDocumentTypeModal"
    :document-type="selectedDocumentType"
    :update-document-type-is-loading="updateDocumentTypeIsLoading"
    @close="closeUpdateDocumentTypeModal"
    @confirmUpdate="confirmUpdateDocumentType"
  />
  <split-document-modal
    v-if="annotations"
    :show="showSplitDocumentModal"
    :document-annotations="annotations"
    :is-loading="splitDocumentIsLoading"
    @close="closeSplitDocumentModal"
    @confirm="splitDocument"
  />
</template>
<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import defaultVerifyMenuActions from '@/store/helpers/mapping/defaultVerifyMenuActions';
import { extractionProgressMappingToBackend } from '@/store/helpers/mapping/extractionProgresses';
import PspdfkitWrapperViewer from '@/components/pdfReader/PspdfkitWrapperViewer.vue';
import VerifyNav from '@/components/verify/VerifyNav.vue';
import BreadcrumbNav from '@/components/verify/BreadcrumbNav.vue';
import MetricSection from '@/components/verify/MetricSection.vue';
import isSet from '@/store/helpers/isSet';
import { allowedStates } from '@/store/helpers/storeState';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';
import UpdateDocumentTypeModal from '@/components/modals/UpdateDocumentTypeModal.vue';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import SplitDocumentModal from '@/components/modals/SplitDocumentModal.vue';
import Api from '@/store/helpers/api';
import ReadOnlyBanner from '@/components/general/banners/ReadOnlyBanner.vue';

export default {
  components: {
    MetricSection,
    BreadcrumbNav,
    VerifyNav,
    Pane,
    PspdfkitWrapperViewer,
    Splitpanes,
    UpdateDocumentTypeModal,
    ReadOnlyBanner,
    SplitDocumentModal,
  },
  beforeRouteLeave(to, from, next) {
    // If there are changes to save and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmRemainUnsaved()) {
      next(false);
    } else {
      next();
    }
  },
  props: {
    // These props are sent from the url. Because breadcrumbIndex is a Number,
    // it is validated in index.js - this unfortunately means that any props
    // added or removed here, also need to be updated in index.js to be passed in here.
    documentRequestId: {
      type: String,
      required: true,
    },
    breadcrumbIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isResizingPanes: false,
      attemptingConfirmation: false,
      attemptingDocumentStatusUpdate: { isLoading: false, transitionName: '' },
      documentStatusUpdateRequest: null,
      localDocumentPath: `${window.location.origin}/local_documents/performance.pdf`,
      toast: useToast(),
      verifyMenuActionTriggers: defaultVerifyMenuActions(),
      showViewerPane: true,
      showUpdateDocumentTypeModal: false,
      selectedDocumentType: null,
      showSplitDocumentModal: false,
      splitDocumentIsLoading: false,
      updateDocumentTypeIsLoading: false,
      defaultTabTitle: window.document.title,
      previousViewQueryParameters: '',
    };
  },
  computed: {
    ...mapGetters({
      autoSaveStatus: 'verifyDocument/autoSaveStatus',
      confirmDocumentStatus: 'verifyDocument/confirmDocumentStatus',
      canRedo: 'verifyDocument/canRedo',
      canVerifyRow: 'verifyDocument/canVerifyRow',
      canVerifyColumn: 'verifyDocument/canVerifyColumn',
      document: 'verifyDocument/document',
      readOnlyMode: 'localisation/documentsReadOnlyMode',
      annotations: 'annotations/all',
    }),
    enableUXRefresh() {
      return FEATURE_FLAGS.ENABLE_UX_REFRESH;
    },
    grouping() {
      return this.document ? this.document.grouping : [];
    },
    tables() {
      return this.document ? this.document.tables : {};
    },
    breadcrumbs() {
      if (!Object.keys(this.grouping).length || !this.grouping.groups.length) {
        return [];
      }

      return this.grouping.groups.map((g) => ({
        navText: g.groupDisplay,
      }));
    },
    groupForCurrentBreadcrumb() {
      if (!this.grouping?.groups) {
        return null;
      }
      return this.grouping.groups[this.breadcrumbIndex];
    },
    defaultTranspose() {
      return [
        'am:performance:fund',
        'am:performance:income_statement',
        'am:performance:cash_flow',
        'am:performance:reporting_period',
      ].includes(this.groupForCurrentBreadcrumb.groupIdentifier);
    },
    tableForCurrentBreadcrumb() {
      const failedToLoadTable = { tableIndex: -1, table: undefined };
      if (!this.groupForCurrentBreadcrumb) {
        return failedToLoadTable;
      }
      const { tableIndex } = this.groupForCurrentBreadcrumb;
      if (!isSet(tableIndex)) {
        this.$log.error('Each breadcrumb should only have one table. Given:', tableIndex);
        return failedToLoadTable;
      }
      const table = this.tables[tableIndex];
      const isRepeating = isSet(this.groupForCurrentBreadcrumb.groupIdentifier);
      const hasCalculatedTotals = isRepeating ? this.groupForCurrentBreadcrumb.calculatedTotals : false;

      return {
        tableIndex, table, isRepeating, hasCalculatedTotals,
      };
    },
  },
  watch: {
    document(loadedDocument) {
      if (loadedDocument) {
        window.document.title = `${this.defaultTabTitle} - ${loadedDocument.fileName}`;
      }
    },
    autoSaveStatus(newStatus) {
      // A good vuex candidate:
      this.$log.info('autoSaveStatus watch:', newStatus, 'attempting confirmation:', this.attemptingConfirmation);

      if (FEATURE_FLAGS.ENABLE_NEW_USER_ACCEPTANCE_WORK_FLOW) {
        this.$log.info('autoSaveStatus has changed:', newStatus,
          'attempting document status update:', this.attemptingDocumentStatusUpdate, this.documentStatusUpdateRequest);
        if (this.attemptingDocumentStatusUpdate.isLoading) {
          if (newStatus === allowedStates.IS_READY) {
            this.updateDocumentStatus();
            return;
          }
          if (newStatus === allowedStates.IS_ERRORING) {
            this.attemptingDocumentStatusUpdate = { isLoading: false, transitionName: '' };
            this.$log.error('Document status update stopped due to auto-save error');
            this.documentStatusUpdateRequest = null;
          }
        }
        return;
      }

      // TODO: Remove below code when feature flag is removed
      if (this.attemptingConfirmation) {
        if (newStatus === allowedStates.IS_READY) {
          this.confirmDocument();
          this.attemptingConfirmation = false;
          return;
        }
        if (newStatus === allowedStates.IS_ERRORING) {
          this.attemptingConfirmation = false;
          this.$log.error('Document confirmation stopped due to auto-save error');
        }
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
    this.previousViewQueryParameters = this.$route.query.previousViewQueryParams ?? '';
  },
  beforeUnmount() {
    this.$log.debug('Unmounting metric table');
    window.document.title = this.defaultTabTitle;
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
    this.documentRequestReset();
  },
  mounted() {
    this.newDocReqInit();
  },
  methods: {
    ...mapActions({
      documentRequestInit: 'verifyDocument/init',
      documentRequestReset: 'verifyDocument/reset',
      getSettings: 'localisation/lazyInit',
      initSettingsWithDefault: 'localisation/initWithDefault',
      postDocumentRequestIsVerifiedStatus: 'verifyDocument/postDocumentRequestIsVerifiedStatus',
      postDocumentRequestState: 'verifyDocument/postDocumentRequestState',
      postTransitionDocumentRequest: 'verifyDocument/postTransitionDocumentRequest',
      requeueDocuments: 'documents/requeueDocuments',
    }),
    confirmLeave() {
      // eslint-disable-next-line no-alert
      return window.confirm('You have changes that have not been saved. Click Cancel to go back or OK to save and close');
    },
    confirmRemainUnsaved() {
      return [allowedStates.IS_PENDING, allowedStates.IS_LOADING].includes(this.autoSaveStatus)
        && !this.confirmLeave();
    },
    beforeWindowUnload(e) {
      if (this.confirmRemainUnsaved()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    newDocReqInit() {
      this.$log.info('newDocReqInit. Resetting and fetching new data...');
      this.resetData();

      const getSettingsProm = this.getSettings()
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching user settings, initialising with defaults');
          this.initSettingsWithDefault();
        });

      const documentRequestInit = this.documentRequestInit({ documentRequestId: this.documentRequestId })
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching metrics for this document');
        });

      Promise.all([getSettingsProm, documentRequestInit])
        .then(() => {
          this.$log.info('Successfully initialised data for VerifyDocument');
          this.$log.info('Loaded groups:', this.grouping);
        });
    },
    resetData() {
      this.verifyMenuActionTriggers = defaultVerifyMenuActions();
    },
    async onUpdateDocumentStatus({ transition, justification }) {
      this.documentStatusUpdateRequest = { transition, justification };
      this.attemptingDocumentStatusUpdate = { isLoading: true, transitionName: transition };
      this.onVerifyMenuActionTriggered(verifyMenuActions.FULL_SAVE);
    },
    onSplitDocumentTriggered() {
      this.showSplitDocumentModal = true;
    },
    async splitDocument({ splitText, addTag }) {
      const idToken = this.$store.getters['authenticate/idToken'];
      this.splitDocumentIsLoading = true;
      return (new Api(process.env, idToken))
        .post(`document-requests/${this.documentRequestId}/split-document`, { splitText, addTag })
        .then(() => {
          this.toast.success('Successfully queued document for splitting.\nYour new documents will begin uploading shortly.', {
            timeout: 3000,
          });
          this.onClosePressed();
        }).catch(() => {
          this.toast.error('Failed to split document');
        })
        .finally(() => { this.splitDocumentIsLoading = false; });
    },
    closeSplitDocumentModal() {
      this.showSplitDocumentModal = false;
    },
    updateDocumentStatus() {
      const { transition, justification } = this.documentStatusUpdateRequest;
      this.postTransitionDocumentRequest({
        documentRequestId: this.documentRequestId,
        transition,
        ...justification && { justification },
      })
        .then(() => {
          this.toast.success('Successfully updated document status');
          this.onClosePressed();
        })
        .catch(() => {
          this.toast.error('Failed to update document status');
        })
        .finally(() => {
          this.attemptingDocumentStatusUpdate = { isLoading: false, transitionName: '' };
          this.documentStatusUpdateRequest = null;
        });
    },
    async onConfirmTriggered(e) {
      if (e.shiftKey) {
        this.toast.info('Confirm triggered with shift key held');
        await this.postDocumentRequestState({
          documentRequestId: this.documentRequestId, isVerified: true, stage: extractionProgressMappingToBackend.EXT_PREPARED,
        }).then(() => {
          this.toast.success('Document successfully prepared');
        });
        return;
      }
      this.$log.info('Confirm triggered');
      this.attemptingConfirmation = true;
      this.onVerifyMenuActionTriggered(verifyMenuActions.FULL_SAVE);
    },
    confirmDocument() {
      return this.postDocumentRequestIsVerifiedStatus({
        documentRequestId: this.documentRequestId,
        isVerified: true,
      })
        .then(() => {
          this.toast.success('Document successfully confirmed as verified');
          this.onClosePressed();
        })
        .catch((e) => {
          const msg = 'Something went wrong while setting the verification status of this pdf to \'Verified\'';
          this.toast.error(msg);
          throw (e);
        });
    },
    onVerifyMenuActionTriggered(action) {
      this.verifyMenuActionTriggers[action] += 1;
    },
    queryStringToJson(queryString) {
      const params = new URLSearchParams(queryString);
      const json = {};
      params.forEach((value, key) => {
        json[key] = value;
      });
      return json;
    },
    onClosePressed() {
      this.$log.info('Closing document');
      let routeName = 'Verification';

      // Check if there is a valid previous route to go back to.
      if (this.$route.query.previousView) {
        const allRoutes = [];
        this.$router.options.routes.forEach((route) => {
          allRoutes.push(route.name);
        });
        if (allRoutes.includes(this.$route.query.previousView)) {
          this.$log.info('Previous view found in query:', this.$route.query.previousView);
          routeName = this.$route.query.previousView;
        } else {
          this.$log.warn('Previous view in query does not exist:', this.$route.query.previousView);
        }
      }

      return this.$router.push({
        name: routeName,
        query: {
          type: this.$route.query.type,
          // Persist query parameters used in the previous view when returning to it
          ...this.queryStringToJson(this.previousViewQueryParameters),
        },
      });
    },
    onPopoutToggled() {
      if (this.showViewerPane) {
        const routeData = this.$router.resolve({ path: `/verification/${this.documentRequestId}/document` });
        window.open(routeData.href, '_blank', 'toolbar=0,location=0,menubar=0');
      }
      this.showViewerPane = !this.showViewerPane;
    },
    closeUpdateDocumentTypeModal() {
      this.showUpdateDocumentTypeModal = false;
    },
    onUpdateDocumentType(documentType) {
      this.selectedDocumentType = documentType;
      this.updateDocumentTypeIsLoading = false;
      this.showUpdateDocumentTypeModal = true;
    },
    confirmUpdateDocumentType() {
      this.updateDocumentTypeIsLoading = true;
      this.$log.info('Confirm update document type:', this.selectedDocumentType);
      const documents = [
        {
          document_request_id: this.documentRequestId,
          document_type: this.selectedDocumentType.id,
        },
      ];
      this.requeueDocuments(documents)
        .then(() => {
          this.onClosePressed();
        })
        .catch((e) => {
          this.toast.error(errorMessages.REQUEUE_DOCUMENT);
          this.updateDocumentTypeIsLoading = false;
          this.$log.error('Requeue document failed', e);
        });
    },
  },
};
</script>
<style lang="scss">
.pane-invisible-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.verification-page-v2 {
  background-color: #F7FAFA;

  .right-of-input, .left-of-input {
    justify-content: center;
  }

  .metric-info-icon {
    width: 25px;
  }

  .verify-document-nav {
    min-height: unset;
    height: 50px;
    padding: 16px 16px 12px 16px;
    background: var(--background-System-_primary, #FFF);

    .left-group, .right-group {
      padding: 0;
    }
    .verify-nav-v2 { min-height: unset; height: 100%; }
  }

  .document-name {
    overflow: hidden;
    color: var(--foreground-_Primary, #1A2C2D);
    margin: 0;

    /* Heading/H4 */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    text-align: center;
    @include multi-line-ellipsis(1);
  }

  .metric-section-container {
    display: flex;
    flex-direction: column;
  }

  .metric-table-and-filters {
    padding-top: 0;
  }

  .metric-table-secondary {
    flex: 1 1 auto; // Do not grow or shrink, take up only the space it needs
  }

  .metric-table-filter-group {
    display: flex;
    margin: 0;
    width: 100%;
    background: var(--background-System-_primary, #FFF);
    padding: 12px 0px 0px 0px;
    min-height: 63px;

    &.metric-table-filter-group > :not(:last-child) {
      margin-right: 0;
    }

    .metric-table-secondary-filters {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 4px;
      gap: 8px;
      border-bottom: 1px solid #AFCDD0;
      padding: 4px 8px;
    }

    .filter-container {
      // flex: 1 1 auto; // Grow and shrink as necessary, take up remaining space
      overflow-x: hidden; // Enable scrolling if overflow occurs

      .filter-group {
        margin: 0;
        overflow-x: hidden;

        &:hover {
          overflow-x: auto;
        }
      }
    }

    .filter-group {
      width: 100%;
      // overflow-x: auto;
      display: flex;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
    }

    .filter-group-item {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      color: var(--foreground-_LinkAction, #426D70);

      display: flex;
      padding: var(--Unit-3, 8px) var(--Unit-7, 24px);
      justify-content: center;
      align-items: center;
      gap: var(--Unit-3, 8px);
      align-self: stretch;
      border-radius: 4px 4px 0px 0px;
      border: none;
      border-bottom: 1px solid #AFCDD0;

      &:hover:not(&.filter-group-item-active) {
        background-color: #F7FAFA;
      }

      &:active:not(&.filter-group-item-active) {
        background: #DFEBEC;
      }

      &.filter-group-item-active {
        border-top: 1px solid var(--stroke-_BrandSecondary, #AFCDD0);
        border-right: 1px solid var(--stroke-_BrandSecondary, #AFCDD0);
        border-left: 1px solid var(--stroke-_BrandSecondary, #AFCDD0);
        border-bottom: none;
        -webkit-box-shadow: 0px 10px 0px 0px rgba(247,250,250,1);
        -moz-box-shadow: 0px 10px 0px 0px rgba(247,250,250,1);
        box-shadow: 0px 10px 0px 0px rgba(247,250,250,1);
        background: var(--background-System-_secondary-A, #F7FAFA);
      }
    }
  }
}

// Metric Table
.verification-page-v2 .metric-table-layout {
  padding-left: 0;
  background-color: #F7FAFA;

  .metric-table {
    table {
      padding: 12px 8px 24px 12px;
      tbody {
        border-radius: var(--Radius-Radius-3, 12px);
        border: 1px solid var(--stroke-_primary, #E5E5E5);
      }

      td, th {
        border: 1px solid var(--stroke-_secondary, #F2F2F2);
      }

      .column-header {
        min-width: 166px;
        .column-header-label {
          padding-left: 4px;
        }
      }
    }

    // Header
    .row-header:first-child, .column-header, .entity-name-cell-v2, .calculated-total-cell-v2 {
      color: var(--foreground-_Primary, #1A2C2D);
      /* Labels/Labels 2 */
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }

    .text-cell-input, .date-input, .currency-selector, .numeric-cell-content {
      display: flex;
      padding: var(--Unit-2, 4px) 8px var(--Unit-2, 4px) 12px;
      text-align: left;
      min-height: 30px;

      border-radius: 8px;
      border: 1px solid var(--stroke-_secondary, #F2F2F2);
      background: var(--background-System-_secondary-A, #F7FAFA);

      color: var(--foreground-_Primary, #1A2C2D);

      /* Body/Body 2 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .table-cell {
      cursor: pointer;

      .table-cell-content {
        display: flex;
        gap: 8px;
      }

      .table-cell-container:not(.is-verified) {
          &:hover, &:active {
            outline: 1.5px solid var(--stroke-_focus, #B3C5C6);
            background: var(--background-System-_secondary-A, #F7FAFA);
          }
      }

      &.is-selected, &.is-highlighted {
        border: 1.5px solid var(--stroke-_focus, #B3C5C6);

        .table-cell-container {
          background: var(--background-System-_secondary-A, #F7FAFA);
        }
      }

      .is-verified .table-cell-content {
        background: #cfe1e3;
      }
    }

    .aka-table-cell {
      .table-cell-container {
        padding: 0;
        position: relative;

        .aka-cell-alert-icon {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .table-cell-dropdown {
      min-width: 404px;
    }
  }
}

// Dropdowns
.verification-page-v2 {
  .multiselect-v2 {
    width: 280px;
  }
}
</style>
