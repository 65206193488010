<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.375 10.375L1.625 1.625L10.375 10.375ZM10.375 1.625L1.625 10.375L10.375 1.625Z"
      fill="currentColor"
    />
    <path
      d="M10.375 10.375L1.625 1.625M10.375 1.625L1.625 10.375"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
