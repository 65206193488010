<template>
  <div
    class="empty-metric-table-row"
  >
    <p class="hint empty-metric-table-content">
      No entities have been extracted. <br><a
        href="#"
        @click="onAddNewRow"
      >Click here</a> to add a new entity
    </p>
  </div>
</template>
<script>
export default {
  emits: ['addNewRow'],
  methods: {
    onAddNewRow() {
      this.$emit('addNewRow');
    },
  },
};
</script>
