<template>
  <text-cell
    :class="{'entity-name-cell-v2': enableUXRefresh}"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-verified="isVerified"
    :is-read-only="true"
    :show-confidence="false"
    :is-multi-line="true"
    :hide-info-icon="true"
    :root-element-type="rootElementType"
    :can-show-verify-toggle="showVerifiedToggle"
    @cellSelected="onHeaderSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
  >
    <template #right-of-input>
      <floating-dropdown
        :is-enabled="true"
        :custom-classes="{'bottom-right': true}"
      >
        <template #trigger>
          <span
            class="icon-container"
            :class="{'icon-container-v2': enableUXRefresh}"
          >
            <more-vertical-icon size="1x" />
          </span>
        </template>
        <template #content>
          <div
            class="dropdown-compound-item"
            :class="{'dropdown-compound-item-v2': enableUXRefresh}"
            @click="onRenameEntity"
          >
            Update Entity Group
          </div>
          <div
            v-if="!isRepeating && modelValue"
            class="dropdown-compound-item"
            :class="{'dropdown-compound-item-v2': enableUXRefresh}"
            @click="onRemoveEntity"
          >
            Remove Entity
          </div>
          <div
            v-if="isRepeating && isRowHeader"
            class="dropdown-compound-item"
            :class="{'dropdown-compound-item-v2': enableUXRefresh}"
            @click="onAddNewRowAbove"
          >
            Add Group Before
          </div>
          <div
            v-if="isRepeating && isRowHeader"
            class="dropdown-compound-item"
            :class="{'dropdown-compound-item-v2': enableUXRefresh}"
            @click="onAddNewRowBelow"
          >
            Add Group After
          </div>
          <div
            v-if="isRepeating && isRowHeader"
            class="dropdown-compound-item"
            :class="{'dropdown-compound-item-v2': enableUXRefresh}"
            @click="onDeleteRow"
          >
            Delete Group
          </div>
        </template>
      </floating-dropdown>
    </template>
  </text-cell>
</template>
<script>
import clonedeep from 'lodash.clonedeep';
import { MoreVerticalIcon } from '@zhuowenli/vue-feather-icons';
import TextCell from '@/components/verify/cellTypes/TextCell.vue';
import FloatingDropdown from '@/components/forms/FloatingDropdown.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  components: {
    FloatingDropdown,
    TextCell,
    MoreVerticalIcon,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    metricData: {
      type: Object,
      required: true,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    rootElementType: {
      type: String,
      required: true,
    },
    isRepeating: {
      type: Boolean,
      required: true,
    },
    isRowHeader: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'cellValueUpdated',
    'cellCurrencyValueUpdated',
    'cellAkasUpdated',
    'addNewRowBelow',
    'addNewRowAbove',
    'deleteRow',
    'headerSelected',
    'toggleHeaderVerified',
    'renameEntity',
    'removeEntity',
  ],
  data() {
    return {
      showVerifiedToggle: this.isSelected,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    metricDisplay() {
      return this.metricData?.datapoints?.length ? this.metricData.datapoints[0].value : '-';
    },
    modelValue() {
      const dp = this.metricData.datapoints[0];
      if (this.metricData.metricType === 'AKA') {
        return dp.effectors.akas ? clonedeep(dp.effectors.akas) : [];
      }

      return dp.value;
    },
  },
  watch: {
    isSelected(newIsSelected) {
      this.showVerifiedToggle = newIsSelected;
    },
  },
  methods: {
    onHeaderSelected() {
      this.$emit('headerSelected');
      this.showVerifiedToggle = true;
    },
    onVerifyToggled() {
      this.$emit('toggleHeaderVerified');
      this.showVerifiedToggle = false;
    },
    onValueUpdated(newValue) {
      this.$emit('cellValueUpdated', newValue);
    },
    onCurrencyValueUpdated(newValue) {
      this.$emit('cellCurrencyValueUpdated', newValue);
    },
    onAkasUpdated(newValue) {
      this.$emit('cellAkasUpdated', newValue);
    },
    onAddNewRowBelow() {
      this.$emit('addNewRowBelow');
    },
    onAddNewRowAbove() {
      this.$emit('addNewRowAbove');
    },
    onRenameEntity() {
      this.$emit('renameEntity');
    },
    onDeleteRow() {
      this.$emit('deleteRow');
    },
    onRemoveEntity() {
      this.$emit('removeEntity');
    },
  },
};
</script>
<style lang="scss" scoped>
 .icon-container-v2 {
  &:hover {
    background: none !important;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.60), 0px 0px 0px 3px #426D70;
  }

  &:active {
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.60), 0px 0px 0px 3px #426D70;
    background: #D9E2E2 !important;
  }
}
</style>
<style lang="scss">
.dropdown-compound-item-v2 {
  color: #1A2C2D;
  /* Body/Body 2 */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  &:hover {
    background: #F7FAFA !important;
  }

  &:active {
    background: #DFEBEC !important;
  }
}
</style>
