<template>
  <div
    v-if="isActive"
    class="alternative-answers-container"
  >
    <p
      v-show="showText"
      class="m-ver-none m-hoz-s hint"
    >
      Alternative<br>Answers:
    </p>
    <div
      v-for="(datapoint, idx) in showAlternativeDatapoints"
      v-show="showDatapoint(datapoint)"
      :key="idx"
      class="alternative-answer"
    >
      <action-button
        button-display="BTN_NEUTRAL"
        :message="String(datapoint)"
        :custom-classes="{'m-hoz-s': true, 'selected': datapoint === alternativeAnswers[0]}"
        @onClick="onAlternativeAnswerSelected(idx)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ActionButton from '@/components/general/buttons/ActionButton.vue';

export default {
  components: { ActionButton },
  props: {},
  emits: ['alternativeAnswerSelected'],
  computed: {
    ...mapGetters({
      breadcrumbIndex: 'verifyDocumentAlternativeAnswers/breadcrumbIndex',
      alternativeAnswers: 'verifyDocumentAlternativeAnswers/alternativeAnswerDisplays',
    }),
    showAlternativeDatapoints() {
      if (this.alternativeAnswers.length <= 1) {
        return [];
      }
      return this.alternativeAnswers.slice(1);
    },
    isActive() {
      return this.$route.params.breadcrumbIndex === String(this.breadcrumbIndex);
    },
    showText() {
      return !!this.showAlternativeDatapoints.filter((d) => this.showDatapoint(d)).length;
    },
  },
  methods: {
    ...mapActions({
      updateSelectedAlternativeAnswerIndex: 'verifyDocumentAlternativeAnswers/updateSelectedAlternativeAnswerIndex',
    }),
    onAlternativeAnswerSelected(idx) {
      this.$log.info('alternative answer selected at index:', idx, 'real:', idx + 1);
      this.updateSelectedAlternativeAnswerIndex({ index: idx + 1 });
    },
    showDatapoint(dp) {
      return dp !== '';
    },
  },
};
</script>
