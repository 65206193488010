import isString from './isString';

const regexEscape = (s) => s.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');

/**
 * Convert string to a number
 * Using regexes from https://github.com/UziTech/number-string
 *
 * @param str
 * @param decimalPoint
 * @returns {number}
 */
const strToNumber = (str, { decimalPoint = '.' } = {}) => {
  const regexDecimalPoint = regexEscape(decimalPoint);
  let s = str.trim();
  const isNegative = s.match(/^\(.*\)$|^-/); // negative if matches '(...)' or '-...'
  const getNumberRegexp = new RegExp(`[^\\d${regexDecimalPoint}]|${regexDecimalPoint
  }(?=.*${regexDecimalPoint})|^\\D*${regexDecimalPoint}\\D*$`, 'g');
  s = s.replace(getNumberRegexp, '').replace(decimalPoint, '.'); // remove all except digits and last dot

  if (s === '') {
    s = NaN;
  } else if (isNegative) {
    s = -s;
  }

  return Number(s);
};

const toNumber = (x) => {
  if (typeof x === 'number') {
    return x;
  }
  if (!isString(x)) {
    return NaN;
  }

  return strToNumber(x);
};

export default toNumber;
