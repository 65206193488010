<template>
  <div
    class="top-nav-groups top-nav-spaced-groups"
    :class="{ ...customClasses, 'verify-nav-v2': enableUXRefresh}"
  >
    <div class="left-group">
      <floating-dropdown
        :is-enabled="true"
        :custom-classes="{'dropdown-position-below': true}"
        :custom-dropdown-style="{'min-width': 'fit-content', padding: '8px 0px'}"
      >
        <template #trigger>
          <div class="top-nav-item top-nav-item-menu">
            View
          </div>
        </template>
        <template #content>
          <div
            class="dropdown-compound-item"
            @click="onTransposeTable"
          >
            Transpose table
          </div>
          <div
            class="dropdown-compound-item confidence-indicator-toggle"
            @click="onToggleConfidenceIndicators"
          >
            Confidence Indicators
            <span
              v-if="showConfidenceIndicators"
              class="confidence-toggle-icon"
            >
              <eye-icon size="0.5x" />
            </span>
            <span
              v-else
              class="confidence-toggle-icon"
            >
              <eye-off-icon size="0.5x" />
            </span>
          </div>
        </template>
      </floating-dropdown>
      <floating-dropdown
        v-if="!readOnlyMode"
        :is-enabled="true"
        :custom-classes="{'dropdown-position-below': true}"
        custom-dropdown-style="min-width: 240px;"
      >
        <template #trigger>
          <div class="top-nav-item top-nav-item-menu">
            Edit
          </div>
        </template>
        <template #content>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onAddNewRowAbove"
          >
            Create group at the start
          </div>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onAddNewRowBelow"
          >
            Create group at the end
          </div>
          <div
            :class="isRepeating ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onDeleteSelectedRow"
          >
            Delete selected group
          </div>
          <hr>
          <div
            class="dropdown-compound-item"
            @click="onUpdateTableCurrency"
          >
            Change currency
          </div>
          <div
            class="dropdown-compound-item"
            @click="onIncreaseTableScale"
          >
            Increase Currency Scale (000)
          </div>
          <div
            class="dropdown-compound-item"
            @click="onDecreaseTableScale"
          >
            Decrease Currency Scale (000)
          </div>
          <hr>
          <div
            class="dropdown-compound-item"
            @click="onVerifyTable"
          >
            Verify Table
          </div>
          <div
            :class="canVerifyColumn ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onVerifyColumn"
          >
            Verify Column
          </div>
          <div
            :class="canVerifyRow ? 'dropdown-compound-item' : 'dropdown-compound-item-disabled'"
            @click="onVerifyRow"
          >
            Verify Row
          </div>
        </template>
      </floating-dropdown>
      <vue-multi-select
        :class="{'multiselect-v2': enableUXRefresh}"
        class="table-cell-dropdown"
        style="width: 15vw !important"
        placeholder="-- Select Option --"
        :model-value="selectedDocumentType"
        :options="documentTypeOptions"
        track-by="id"
        label="name"
        :allow-empty="false"
        :disabled="!canRequeueDocuments"
        @update:model-value="updateDocumentType"
      />
      <ActionButtonRefresh
        v-if="enableSplitDocument"
        v-tooltip="'Split Document'"
        :icon-only="true"
        variant="TERTIARY"
        :style="{'margin-left': '8px'}"
        @on-click="$emit('splitDocument')"
      >
        <template #icon>
          <MultiDocumentsIcon />
        </template>
      </ActionButtonRefresh>
    </div>
    <div class="document-name">
      {{ trimmedFileName }}
    </div>
    <div class="right-group">
      <ActionButtonRefresh
        v-if="enableUXRefresh && !readOnlyMode"
        v-tooltip="'Undo (Ctrl + Z)'"
        :icon-only="true"
        variant="TERTIARY"
        :is-disabled="!canUndo"
        @on-click="onUndo"
      >
        <template #icon>
          <UndoRedoIcon
            is="undo"
          />
        </template>
      </ActionButtonRefresh>
      <div
        v-else-if="!readOnlyMode"
        v-tooltip="'Undo (Ctrl + Z)'"
        :class="canUndo ? 'top-nav-icon icon-container': 'top-nav-icon'"
        @click="onUndo"
      >
        <corner-up-left-icon
          size="1.5x"
          :stroke="canUndo ? 'black' : '#5e5c5c'"
        />
      </div>
      <ActionButtonRefresh
        v-if="enableUXRefresh && !readOnlyMode"
        v-tooltip="'Redo (Ctrl + Shift + Z)'"
        :icon-only="true"
        variant="TERTIARY"
        :is-disabled="!canRedo"
        @on-click="onRedo"
      >
        <template #icon>
          <UndoRedoIcon
            is="redo"
          />
        </template>
      </ActionButtonRefresh>
      <div
        v-else-if="!readOnlyMode"
        v-tooltip="'Redo (Ctrl + Shift + Z)'"
        :class="canRedo ? 'top-nav-icon icon-container': 'top-nav-icon'"
        @click="onRedo"
      >
        <corner-up-right-icon
          size="1.5x"
          :stroke="canRedo ? 'black' : '#5e5c5c'"
        />
      </div>
      <ActionButtonRefresh
        v-if="enableUXRefresh && !readOnlyMode"
        :icon-only="false"
        text="Save"
        variant="TERTIARY"
        :is-disabled="disableSaveButton"
        @on-click="save"
      >
        <template #icon>
          <RefreshCwIcon
            v-if="autoSaveStatusIsLoading"
            class="icon-rotate"
          />
          <SaveIcon v-else />
        </template>
      </ActionButtonRefresh>
      <div
        v-else-if="!readOnlyMode"
        v-tooltip="'Save changes'"
        class="top-nav-item icon-container"
        @click="save"
      >
        <refresh-cw-icon
          size="1.5x"
          :class="{'icon-rotate': autoSaveStatusIsLoading}"
          :stroke="autoSaveStatusIsBlank ? '#5e5c5c' : 'black'"
        />
      </div>
      <user-acceptance-action-buttons
        v-if="enableUserFlowActionButtons"
        :document-request-id="documentRequestId"
        :attempting-document-status-update="attemptingDocumentStatusUpdate"
        :transitions="transitions"
        @updateDocumentStatus="({transition, justification}) => $emit('updateDocumentStatus', { transition, justification })"
      />
      <div
        v-else
        v-tooltip="userCanConfirmDocument && 'Confirm verified metrics'"
      >
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          message="CONFIRM"
          :custom-classes="{'top-nav-item': true, 'btn-fixed-width-m': true }"
          :style="{ 'font-size': '18px', 'padding': '2px' }"
          :is-disabled="autoSaveStatusIsLoading || confirmDocumentIsLoading || !userCanConfirmDocument"
          :is-loading="confirmDocumentIsLoading"
          :insufficient-permissions="!userCanConfirmDocument"
          @onClick="verify"
        />
      </div>
      <ActionButtonRefresh
        v-if="enableUXRefresh"
        v-tooltip="'Close document'"
        :icon-only="true"
        variant="TERTIARY"
        @on-click="close"
      >
        <template #icon>
          <CrossIcon />
        </template>
      </ActionButtonRefresh>
      <div
        v-else
        v-tooltip="'Close document'"
        class="top-nav-item icon-container"
        @click="close"
      >
        <x-icon
          size="2.0x"
          class="custom-class"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  RefreshCwIcon, XIcon, CornerUpLeftIcon, CornerUpRightIcon, EyeIcon, EyeOffIcon,
} from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';
import { allowedStates } from '@/store/helpers/storeState';
import FloatingDropdown from '@/components/forms/FloatingDropdown.vue';
import VueMultiSelect from 'vue-multiselect';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import ActionButton from '../general/buttons/ActionButton.vue';
import UserAcceptanceActionButtons from './UserAcceptanceActionButtons.vue';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';
import SaveIcon from '../icons/SaveIcon.vue';
import UndoRedoIcon from '../icons/UndoRedoIcon.vue';
import CrossIcon from '../icons/CrossIcon.vue';
import MultiDocumentsIcon from '../icons/MultiDocumentsIcon.vue';

export default {
  components: {
    ActionButton,
    ActionButtonRefresh,
    FloatingDropdown,
    RefreshCwIcon,
    CrossIcon,
    MultiDocumentsIcon,
    XIcon,
    UndoRedoIcon,
    CornerUpLeftIcon,
    CornerUpRightIcon,
    EyeIcon,
    EyeOffIcon,
    SaveIcon,
    VueMultiSelect,
    UserAcceptanceActionButtons,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    attemptingTransitionName: {
      type: String,
      required: false,
      default: '',
    },
    attemptingDocumentStatusUpdate: {
      type: Object, // { isLoading: boolean, transitionName: string }
      required: true,
    },
    autoSaveStatus: {
      type: String,
      required: true,
    },
    confirmDocumentStatus: {
      type: String,
      required: true,
    },
    canRedo: {
      type: Boolean,
      default: false,
    },
    isRepeating: {
      type: Boolean,
      default: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    extractionProgressStageName: {
      type: String,
      required: true,
    },
    documentRequestId: {
      type: String,
      required: true,
    },
    documentTypeId: {
      type: String,
      required: true,
    },
    canVerifyRow: {
      type: Boolean,
      required: true,
    },
    canVerifyColumn: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'close',
    'verify',
    'verifyMenuActionTriggered',
    'updateDocumentType',
    'updateDocumentStatus',
    'splitDocument',
  ],
  data() {
    return {
      toast: useToast(),
      selectedDocumentType: null, // Type: {id: string, name: string, reportingEntityType?: { fin: string, name: string }}
      canConfirmDocuments: false,
      showConfidenceIndicators: true,
      canRequeueDocuments: false,
      enableSplitDocument: FEATURE_FLAGS.ENABLE_SPLIT_DOCUMENT,
    };
  },
  computed: {
    ...mapGetters({
      documentTypes: 'documentTypes/documentTypes',
      // documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
      readOnlyMode: 'localisation/documentsReadOnlyMode',
      transitions: 'verifyDocument/transitions',
    }),
    documentTypeOptions() { // {id: string, name: string}[]
      return this.documentTypes ?? [];
    },
    enableUXRefresh() {
      return FEATURE_FLAGS.ENABLE_UX_REFRESH;
    },
    disableSaveButton() {
      return this.autoSaveStatusIsBlank || this.autoSaveStatusIsReady;
    },
    autoSaveStatusIsReady() {
      return this.autoSaveStatus === allowedStates.IS_READY;
    },
    autoSaveStatusIsLoading() {
      return this.autoSaveStatus === allowedStates.IS_LOADING;
    },
    autoSaveStatusIsBlank() {
      return this.autoSaveStatus === allowedStates.IS_BLANK;
    },
    confirmDocumentIsLoading() {
      return this.confirmDocumentStatus === allowedStates.IS_LOADING;
    },
    confirmDocumentIsBlank() {
      return this.confirmDocumentStatus === allowedStates.IS_BLANK;
    },
    canUndo() {
      return this.autoSaveStatus === allowedStates.IS_PENDING;
    },
    trimmedFileName() {
      return this.fileName.replace(/\.[^/.]+$/, '');
    },
    userCanConfirmDocument() {
      return this.canConfirmDocuments || this.extractionProgressStageName === 'EXT_MANUAL_QA';
    },
    enableUserFlowActionButtons() {
      return FEATURE_FLAGS.ENABLE_NEW_USER_ACCEPTANCE_WORK_FLOW;
    },
  },
  watch: {
    documentTypeId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.documentTypeOptions.length) {
          this.selectedDocumentType = this.documentTypeOptions.find((dT) => dT.id === newVal);
        }
      },
    },
  },
  async created() {
    this.canConfirmDocuments = await (this.checkPermission('CAN_VERIFY_DOCUMENTS'));
    this.canRequeueDocuments = await (this.checkPermission('CAN_REQUEUE_DOCUMENTS'));
  },
  mounted() {
    this.getDocumentTypes()
      .then((response) => {
        this.selectedDocumentType = response.find((dT) => dT.id === this.documentTypeId);
      })
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching document types');
      });
  },
  methods: {
    ...mapActions({
      getDocumentTypes: 'documentTypes/lazyGet',
      checkPermission: 'localisation/checkPermission',
    }),
    close() {
      this.$emit('close');
    },
    save() {
      if (this.autoSaveStatusIsBlank) {
        this.toast.info('No data to save');
        return;
      }
      if (this.autoSaveStatusIsLoading) {
        this.toast.warning('Saving already in progress');
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.SAVE);
    },
    verify(e) {
      this.$emit('verify', e);
    },
    onVerifyMenuActionTriggered(action) {
      this.$emit('verifyMenuActionTriggered', action);
    },
    onToggleConfidenceIndicators() {
      this.showConfidenceIndicators = !this.showConfidenceIndicators;
      this.$log.info('onToggleConfidenceIndicators');
      this.onVerifyMenuActionTriggered(verifyMenuActions.TOGGLE_CONFIDENCE_INDICATORS);
    },
    onTransposeTable() {
      this.$log.info('onTransposeTable');
      this.onVerifyMenuActionTriggered(verifyMenuActions.TRANSPOSE_TABLE);
    },
    onAddNewRowAbove() {
      this.$log.info('onAddNewRowAbove');
      this.onVerifyMenuActionTriggered(verifyMenuActions.ADD_NEW_ROW_ABOVE);
    },
    onAddNewRowBelow() {
      this.$log.info('onAddNewRowBelow');
      this.onVerifyMenuActionTriggered(verifyMenuActions.ADD_NEW_ROW_BELOW);
    },
    onDeleteSelectedRow() {
      this.$log.info('onDeleteSelectedRow');
      this.onVerifyMenuActionTriggered(verifyMenuActions.DELETE_SELECTED_ROW);
    },
    onUpdateTableCurrency() {
      this.$log.info('onUpdateTableCurrency');
      this.onVerifyMenuActionTriggered(verifyMenuActions.UPDATE_TABLE_CURRENCY);
    },
    onIncreaseTableScale() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.INCREASE_TABLE_SCALE);
    },
    onDecreaseTableScale() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.DECREASE_TABLE_SCALE);
    },
    onVerifyTable() {
      this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_TABLE);
    },
    onVerifyColumn() {
      if (this.canVerifyColumn) {
        this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_COLUMN);
      }
    },
    onVerifyRow() {
      if (this.canVerifyRow) {
        this.onVerifyMenuActionTriggered(verifyMenuActions.VERIFY_ROW);
      }
    },
    onUndo() {
      if (!this.canUndo) {
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.UNDO);
    },
    onRedo() {
      if (!this.canRedo) {
        return;
      }
      this.onVerifyMenuActionTriggered(verifyMenuActions.REDO);
    },
    updateDocumentType(newValue) {
      this.$log.info('Update document type:', newValue);
      this.$emit('updateDocumentType', newValue);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
<style lang="scss" scoped>
.verify-nav-v2 {
  background: var(--background-System-_primary, #FFF);

  .right-group {
    gap: 8px;
  }

  .top-nav-item-menu {
    color: #1A2C2D;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    &:hover {
      background: #F7FAFA;
    }
  }

  .auto-save-button-content {
    .refresh-icon {
      position: absolute;
      bottom: -2px;
      right: -3px;
      width: 10px;
      height: 10px;
      background: #FFF;
      border-radius: 100000px;
    }
  }

  .dropdown-compound-item {
    padding: 8px 12px;

    color: #1A2C2D;
    text-transform: capitalize;

    &:hover {
      background: #F7FAFA;
    }

    &:active {
      background: #DFEBEC;
    }
  }

  .dropdown-compound-item, .dropdown-compound-item-disabled {
    /* Body/Body 2 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
</style>
