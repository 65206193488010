<template>
  <div
    v-show="enableDebugging"
    class="debug-section"
  >
    <slot name="content" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      enableDebugging: false,
    };
  },
  mounted() {
    if (localStorage.getItem('freyda_debug_mode') === 'yes') {
      this.enableDebugging = true;
    }
  },
};

</script>
<style scoped>
.debug-section {
  background-color: green;
  color: white;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

</style>
