/* eslint-disable prefer-destructuring */

const FILTER_DELIMITER = '//';

/**
 * Divide the filter options into a primary and secondary object of filters.
 * The key of the objects represent the index of the original array.
 * @param {string[]} filterOptions Array of strings
 * @returns {Object[]} [primaryFilters, secondaryFilters]
 */
const convertFilters = (filterOptions) => {
  const primaryFilters = {};
  const secondaryFilters = {};
  filterOptions.forEach((filter, idx) => {
    const filterGroups = filter.split(FILTER_DELIMITER);

    // Handle single filter group.
    if (filterGroups.length === 1) {
      primaryFilters[idx] = filterGroups[0];
      return;
    }

    const filterIdx = Object.keys(primaryFilters).find((key) => primaryFilters[key] === filterGroups[0]);

    // Handle nested filter group.
    if (filterGroups.length === 2) {
      if (filterIdx == null) {
        primaryFilters[idx] = filterGroups[0];
        secondaryFilters[idx] = { [idx]: filterGroups[1] };
      } else {
        secondaryFilters[filterIdx][idx] = filterGroups[1];
      }
      return;
    }

    // Handle nester filter group with name.
    if (filterGroups.length === 3) {
      if (filterIdx == null) {
        primaryFilters[idx] = filterGroups[0];
        secondaryFilters[idx] = { [idx]: filterGroups[2], groupText: filterGroups[1] };
      } else {
        secondaryFilters[filterIdx][idx] = filterGroups[2];
      }
    }
  });
  return [primaryFilters, secondaryFilters];
};

export default convertFilters;
