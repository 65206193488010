import isSet from '@/store/helpers/isSet';
import toNumber from '@/store/helpers/toNumber';
import { logger } from '@/store/logger';
import { Decimal } from 'decimal.js';

export default (annotation) => {
  if (isSet(annotation.nv) && isSet(annotation.nv.n)) {
    const value = annotation.nv.n.v;
    if (isSet(value)) {
      const exponent = annotation.nv.n.e;
      if (isSet(exponent)) {
        return new Decimal(value).times(Decimal.pow(10, exponent)).toNumber(); // Equivalent to: value * (10 ** exponent);
      }
      return value;
    }
  }
  logger.warn('No normalised value found for annotation');
  const casted = toNumber(annotation.t);

  return Number.isNaN(casted) ? null : casted;
};
