import isSet from '../isSet';

// Must be defined in ascending order
const confidenceThresholds = {
  BLANK: {
    display: 'NA',
    upperThreshold: 0.0,
  },
  LOW: {
    display: 'Low',
    upperThreshold: 0.85,
  },
  MEDIUM: {
    display: 'Medium',
    upperThreshold: 0.90,
  },
  HIGH: {
    display: 'High',
    upperThreshold: 100000,
  },
};

const numberToConfidenceThreshold = (c) => {
  if (!isSet(c)) {
    return 'BLANK';
  }

  for (const [k, v] of Object.entries(confidenceThresholds)) { // eslint-disable-line no-restricted-syntax
    if (c <= v.upperThreshold) {
      return k;
    }
  }
  return 'HIGH';
};

const confidenceToColourClass = (c) => {
  const ctToClassMap = {
    BLANK: 'confidence-blank',
    LOW: 'confidence-low',
    MEDIUM: 'confidence-medium',
    HIGH: 'confidence-high',
  };
  const confidenceThreshold = numberToConfidenceThreshold(c);

  return ctToClassMap[confidenceThreshold];
};

export { confidenceThresholds, confidenceToColourClass, numberToConfidenceThreshold };
