<template>
  <div
    class="metric-table-and-filters"
    :class="{
      'metric-table-read-only-mode': readOnlyMode
    }"
  >
    <div
      v-if="!enableUXRefresh || (filterOptions?.length > 1 || numNonReportedColumns > 0)"
      class="metric-table-filter-group"
    >
      <filter-group
        v-if="filterOptions.length && filterOptions.length > 1"
        v-model="currentFilterGroupIdx"
        :filter-options="filterOptions"
      />
      <div class="metric-table-secondary-filters">
        <div id="secondaryFilterGroup" />
        <filter-toggle
          v-model="filterShowNotReported"
          :custom-classes="{'metric-table-filter': true}"
          label-active="Hide Not Reported"
          label-inactive="Show Not Reported"
          :badge-text="numNonReportedColumns > 0 ? String(numNonReportedColumns) : ''"
        />
      </div>
    </div>
    <div class="metric-table-layout">
      <loading-overlay
        v-show="loadingMetricsTable"
        :overlay-opaque="overlayOpaque"
      />
      <div
        id="infinite-scroll"
        class="metric-table"
      >
        <component
          :is="isTransposed ? 'transposed-metric-table' : 'metric-table'"
          :calculated-totals="calculatedTotals"
          :column-headers="columnHeaders"
          :metric-table-is-loading="metricTableIsLoading"
          :column-header-options="columnHeaderOptions"
          :column-verification-flags="columnVerificationFlags"
          :has-entity-name-row-headers="hasEntityNameRowHeaders"
          :rows="rows"
          :metrics="metrics"
          :num-visible-columns="numVisibleColumns"
          :num-visible-rows="numVisibleRows"
          :selected-cells="selectedCells"
          :selected-headers="selectedHeaders"
          :selected-row-headers="selectedRowHeaders"
          :show-calculated-totals="showCalculatedTotals"
          :show-column-indices="showColumnIndices"
          :show-row-indices="showRowIndices"
          :table-idx="tableIdx"
          :is-repeating="isRepeating"
          :has-calculated-totals="hasCalculatedTotals"
          @cellSelected="onCellSelected"
          @rowHeaderSelected="onRowHeaderSelected"
          @headerSelected="onHeaderSelected"
          @verifyHeaderToggled="onToggleHeaderVerified"
          @headerCurrencyChanged="onHeaderCurrencyChanged"
          @headerAdjustScale="onHeaderAdjustScale"
          @headerNumericFormatChanged="onHeaderNumericFormatChanged"
          @toggleCellVerified="onToggleCellVerified"
          @cellValueUpdated="onCellValueUpdated"
          @cellCurrencyValueUpdated="onCellCurrencyValueUpdated"
          @cellAkasUpdated="onCellAkasUpdated"
          @cellSelectedEntityUpdated="onCellSelectedEntityUpdated"
          @addNewRowAt="addNewRowAt"
          @deleteRowAt="deleteRowAt"
          @renameEntityAt="onRenameEntityAt"
          @removeEntity="onRemoveEntity"
          @setLoading="onSetLoading"
        />
        <entity-modal
          :show="showEntityModal"
          :row-idx="newEntityModalRowIdx"
          :metric-fin="entityModalMetricFin"
          :metric-validators="entityModalMetricValidators"
          :initial-value="renamingEntityModalRowValue"
          @submitEntity="onSubmitEntity"
          @close="onEntityModalClose"
        />
        <div v-show="metricTableIsLoading">
          <div class="metric-table-overlay-loading" />
          <div class="lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
      </div>
    </div>
  </div>
  <change-column-currency-modal
    :show="showColumnOptionCurrencyChangeModal"
    :column-idx="columnOptionCurrencyChangeColIdx"
    @close="onColumnOptionCurrencyChangeClose"
    @confirmChangeColumnCurrency="onColumnOptionCurrencyChangeConfirmed"
  />
  <change-table-currency-modal
    :show="showUpdateTableCurrencyModal"
    @close="onUpdateTableCurrencyClose"
    @confirmUpdateTableCurrency="onUpdateTableCurrencyConfirmed"
  />
</template>
<script>
import { provide } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import TransposedMetricTable from '@/components/verify/tableLayout/TransposedMetricTable.vue';
import MetricTable from '@/components/verify/tableLayout/MetricTable.vue';
import EntityModal from '@/components/modals/EntityModal.vue';
import useMetricTableCore from '@/hooks/useMetricTableCore';
import FilterToggle from '@/components/verify/filters/FilterToggle.vue';
import ChangeColumnCurrencyModal from '@/components/modals/ChangeColumnCurrencyModal.vue';
import FilterGroup from '@/components/verify/filters/FilterGroup.vue';
import ChangeTableCurrencyModal from '@/components/modals/ChangeTableCurrencyModal.vue';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  components: {
    TransposedMetricTable,
    MetricTable,
    FilterGroup,
    ChangeColumnCurrencyModal,
    FilterToggle,
    EntityModal,
    ChangeTableCurrencyModal,
    LoadingOverlay,
  },
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    initialTable: {
      type: Object,
      required: true,
    },
    tableIdx: {
      type: Number,
      required: true,
    },
    breadcrumbIdx: {
      type: Number,
      required: true,
    },
    isRepeating: {
      type: Boolean,
      required: true,
    },
    verifyMenuActionTriggers: {
      type: Object,
      required: true,
    },
    hasCalculatedTotals: {
      type: Boolean,
      required: true,
    },
    defaultTransposed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const toast = useToast();
    const metricTable = useMetricTableCore(props, context, store, toast);
    provide('showConfidenceIndicators', metricTable.showConfidenceIndicators);
    provide('readOnlyMode', metricTable.readOnlyMode);

    return { ...metricTable };
  },
  data() {
    return {
      loadingMetricsTable: false,
      overlayOpaque: false,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  mounted() {
    // Check for certain key combinations pressed.
    document.addEventListener('keydown', (event) => {
      if (event.altKey && event.key === '=') {
        this.updateScaleSelectedCells(3);
      } else if (event.altKey && event.key === '-') {
        this.updateScaleSelectedCells(-3);
      } else if (event.ctrlKey && event.key === 'z') {
        event.preventDefault();
        this.undo();
      } else if (event.ctrlKey && ((event.shiftKey && event.key === 'Z') || (event.key === 'y'))) {
        event.preventDefault();
        this.redo();
      }
    });
  },
  methods: {
    onSetLoading({ isLoading, isOpaque = false }) {
      this.loadingMetricsTable = isLoading;
      this.overlayOpaque = isOpaque;
    },
  },
};
</script>
