<template>
  <component
    :is="rootElementType"
    class="sticky-top table-cell column-header"
    :class="{...customClasses, 'is-selected': isSelected}"
    @click="onHeaderSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified}"
    >
      <div class="table-cell-content">
        <div class="table-cell-content-input column-header-label">
          {{ headerText }}
        </div>
        <alert-circle-icon
          v-if="headerDescription"
          v-tooltip="headerDescription"
          size="1x"
          stroke="#000f"
        />
        <div :style="{display: 'flex', gap: '8px'}">
          <metric-verify-toggle
            v-if="canShowVerifyToggle && enableUXRefresh"
            :style="!showColumnOptions ? {'margin-right': '32px'} : {}"
            :v2="true"
            :show-toggle="showVerifiedToggle"
            :is-verified="isVerified"
            @toggleCellVerified="onVerifyToggled"
          />
          <div
            v-if="showColumnOptions"
            class="right-of-input"
            :style="{'width': '23px'}"
          >
            <floating-dropdown
              :is-enabled="true"
              :custom-classes="{'bottom-right': true}"
            >
              <template #trigger>
                <span
                  class="icon-container"
                  :class="{'icon-container-v2': enableUXRefresh}"
                >
                  <more-vertical-icon size="1x" />
                </span>
              </template>
              <template #content>
                <div
                  class="dropdown-compound-item"
                  :class="{'dropdown-compound-item-v2': enableUXRefresh}"
                  @click="onChangeCurrency"
                >
                  Change currency
                </div>
                <div
                  class="dropdown-compound-item"
                  :class="{'dropdown-compound-item-v2': enableUXRefresh}"
                  @click="onIncreaseScale"
                >
                  Increase Scale (000)
                </div>
                <div
                  class="dropdown-compound-item"
                  :class="{'dropdown-compound-item-v2': enableUXRefresh}"
                  @click="onDecreaseScale"
                >
                  Decrease Scale (000)
                </div>
              </template>
            </floating-dropdown>
          </div>
        </div>
      </div>
      <metric-verify-toggle
        v-if="canShowVerifyToggle && !enableUXRefresh"
        :show-toggle="showVerifiedToggle"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </component>
</template>
<script>
import { AlertCircleIcon, MoreVerticalIcon } from '@zhuowenli/vue-feather-icons';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import FloatingDropdown from '@/components/forms/FloatingDropdown.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  components: {
    FloatingDropdown,
    MetricVerifyToggle,
    MoreVerticalIcon,
    AlertCircleIcon,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    headerText: {
      type: String,
      required: true,
    },
    headerDescription: {
      type: String,
      default: '',
    },
    headerOptions: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    rootElementType: {
      type: String,
      default: 'th',
    },
    showColumnOptions: {
      type: Boolean,
      required: true,
    },
    canShowVerifyToggle: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'headerSelected',
    'verifyToggled',
    'currencyChanged',
    'increaseScale',
    'decreaseScale',
    'numericFormatChanged',
  ],
  data() {
    return {
      showVerifiedToggle: this.isSelected,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  watch: {
    isSelected(newIsSelected) {
      this.showVerifiedToggle = newIsSelected;
    },
  },
  methods: {
    onHeaderSelected() {
      this.$emit('headerSelected');
      this.showVerifiedToggle = true;
    },
    onVerifyToggled() {
      this.$emit('verifyToggled');
      this.showVerifiedToggle = false;
    },
    onChangeCurrency() {
      this.$log.info('currency change');
      this.$emit('currencyChanged');
    },
    onIncreaseScale() {
      this.$log.info('Scale increased');
      this.$emit('increaseScale');
    },
    onDecreaseScale() {
      this.$log.info('Scale decreased');
      this.$emit('decreaseScale');
    },
    onChangeNumericFormat() {
      this.$log.info('change numeric format');
      this.$emit('numericFormatChanged');
    },
  },
};
</script>
<style lang="scss" scoped>
 .icon-container-v2 {
  &:hover {
    background: none !important;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.60), 0px 0px 0px 3px #426D70;
  }

  &:active {
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.60), 0px 0px 0px 3px #426D70;
    background: #D9E2E2 !important;
  }
}
</style>
<style lang="scss">
.dropdown-compound-item-v2 {
  color: #1A2C2D;
  /* Body/Body 2 */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  &:hover {
    background: #F7FAFA !important;
  }

  &:active {
    background: #DFEBEC !important;
  }
}
</style>
