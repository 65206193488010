<template>
  <div
    v-if="!v2"
    class="verified-tick-container"
    :class="{'verified-tick-container-v2': enableUXRefresh}"
  >
    <div
      v-if="showToggle"
      :class="{ 'icon-container': true, 'is-verified': isVerified }"
      @click.stop="onVerifiedClick"
    >
      <check-circle-icon
        v-if="!enableUXRefresh"
        size="1.25x"
        stroke="black"
      />
      <verification-icon v-else />
    </div>
  </div>
  <div
    v-else
    class="verify-toggle-button-v2"
  >
    <ActionButtonRefresh
      :text="isVerified ? 'Verified' : 'Verify'"
      :variant="isVerified ? 'PRIMARY' : 'TERTIARY'"
      :style="{width: 'fit-content', 'align-self': 'center', height: '30px'}"
      :icon-only="true"
      @on-click="onVerifiedClick"
    >
      <template #iconRight>
        <VerificationIcon />
      </template>
    </ActionButtonRefresh>
  </div>
</template>
<script>
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { CheckCircleIcon } from '@zhuowenli/vue-feather-icons';
import VerificationIcon from '../icons/VerificationIcon.vue';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';

export default {
  components: {
    CheckCircleIcon,
    VerificationIcon,
    ActionButtonRefresh,
  },
  props: {
    showToggle: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    v2: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleCellVerified'],
  data() {
    return {
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  methods: {
    onVerifiedClick() {
      this.$emit('toggleCellVerified');
    },
  },
};
</script>
<style lang="scss" scoped>
.verify-toggle-button-v2 {
  margin-left: auto;
}

.verified-tick-container-v2 .icon-container {
  border: none;
  border: 1.5px solid var(--stroke-_focus, #B3C5C6);
  color: #426D70;
  &:hover {
    background: var(--background-System-_secondary-A, #F7FAFA);
  }
  &.is-verified, &.is-verified:hover {
    background: var(--background-System-_tertiary, #cfe1e3);
    color: #FFF;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.verify-toggle-v2 {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;
}
</style>
