import { logger } from '@/store/logger';
import dayjs from 'dayjs';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Searches a list of annotations until it finds three consecutive nodes
 * which form a valid date. If found, date is returned in format YYYY-MM-DD.
 */
export default (annotations) => {
  for (let i = 0; i < annotations.length; i++) {
    const annotation = annotations[i];

    // Check if annotation corresponds to a valid month
    const potentialMonth = annotation.data.t;
    if (MONTHS.includes(potentialMonth) && i > 0 && i + 1 < annotations.length) {
      // Concatenate month with previous and following nodes to check if a valid date exists
      const potentialDay = annotations[i - 1].data.t;
      const potentialYear = annotations[i + 1].data.t;
      const date = `${potentialDay} ${potentialMonth} ${potentialYear}`;
      const isValid = dayjs(date).isValid();
      if (isValid) {
        return dayjs(date).format('YYYY-MM-DD');
      }
    }
  }
  logger.warn('No normalised date value found for annotations');
  return null;
};
