<template>
  <div
    class="vertical-align"
    :class="{...customClasses, 'breadcrumbs-v2': enableUXRefresh}"
  >
    <template
      v-for="(breadcrumb, idx) in breadcrumbs"
      :key="breadcrumb.breadcrumb"
    >
      <span
        v-if="idx !== 0"
        class="breadcrumb-separation-icon icon-container-static"
      >
        <span class="pipe-icon">|</span>
      </span>

      <router-link
        class="breadcrumb-text top-nav-item"
        :class="{'breadcrumb-text-selected': idx === currentBreadcrumbIndex}"
        :to="navLink(idx)"
      >
        {{ breadcrumb.navText }}
      </router-link>
    </template>
  </div>
</template>
<script>
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    currentBreadcrumbIndex: {
      type: Number,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  data() {
    return {
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  methods: {
    navLink(index) {
      return `/verification/${this.documentRequestId}/${index}`;
    },
  },
};
</script>
<style scoped lang="scss">
.breadcrumbs-v2 {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;

  .breadcrumb-separation-icon {
    display: none;
  }

  .breadcrumb-text {
    margin: 0;

    display: flex;
    padding: var(--Unit-3, 8px) var(--Unit-5, 24px);
    justify-content: center;
    align-items: center;
    text-transform: capitalize;

    color: var(--foreground-_LinkAction, #426D70);
    text-align: center;
    text-wrap: nowrap;

    /* Button/Button 2 */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: unset;

    &:hover:not(&.breadcrumb-text-selected) {
      background-color: #F7FAFA;
    }
    &:active:not(&.breadcrumb-text-selected) {
      background: #DFEBEC;
    }
  }

  .breadcrumb-text-selected {
    border-bottom: 3px solid var(--stroke-_BrandPrimary, #426D70);
    text-decoration: none !important;
  }
}
</style>
