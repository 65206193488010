import { ref, watch } from 'vue';
import formatNumber from '@/store/helpers/formatNumber';
import toNumber from '@/store/helpers/toNumber';
import { logger } from '@/store/logger';
import { validateMetric } from '@/store/helpers/request/validators/validateMetrics';
import ValidationException from '@/store/helpers/request/exceptions/ValidationException';

export default (props, context) => {
  const modelValue = ref(props.modelValue);
  const numericType = ref(props.numericType);
  const validators = ref(props.validators);
  /* Data */
  const value = ref(null);
  const validationError = ref(null);

  /* Watchers */
  watch(() => props.modelValue, (newVal, oldVal) => {
    validationError.value = null;
    modelValue.value = newVal;
    if (newVal === null) {
      value.value = null;
      return;
    }

    if (newVal !== oldVal) {
      value.value = formatNumber(toNumber(newVal), { thousandSeparator: (numericType.value === 'NUMBER' ? '' : ',') });
    }
  },
  { immediate: true });

  /* Methods */
  const onValueInputBlurred = () => {
    if (value.value === '') { // We want empty strings to show as 'Not Reported'
      value.value = null;
    }
    if (value.value === null) {
      if (value.value !== modelValue.value) {
        context.emit('update:modelValue', value.value);
      }
      return;
    }

    const castedVal = toNumber(value.value);
    if (Number.isNaN(castedVal)) {
      logger.warn('Cell value is not a number', value.value);
      value.value = null;
      return;
    }

    // Make sure value meets the metric validation.
    try {
      validateMetric(castedVal, validators.value);
      if (castedVal !== modelValue.value) {
        context.emit('update:modelValue', castedVal);
      }
    } catch (e) {
      if (e instanceof ValidationException) {
        validationError.value = e.message;
      } else {
        throw e;
      }
    }

    value.value = formatNumber(castedVal, { thousandSeparator: (numericType.value === 'NUMBER' ? '' : ',') });
  };

  const updateValue = (newValue) => {
    // Only update the value if the string conversion does not overflow.
    const castedVal = toNumber(newValue);
    const newValStr = castedVal.toString();
    if (!newValStr.includes('e')) {
      value.value = newValue;
    }
  };

  const updateOptions = (newValue) => {
    updateValue(newValue);
    onValueInputBlurred();
  };

  return {
    value, updateValue, onValueInputBlurred, updateOptions, validationError,
  };
};
