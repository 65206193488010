<template>
  <div
    v-tooltip="tooltipText"
    :class="{'confidence-bars': true, 'hidden': !show, 'confidence-bars-v2': enableUXRefresh}"
  >
    <div
      class="confidence-bar confidence-bar-0"
      :class="confidenceBarColourClass"
    />
    <div
      class="confidence-bar confidence-bar-1"
      :class="confidenceBarColourClass"
    />
    <div
      class="confidence-bar confidence-bar-2"
      :class="confidenceBarColourClass"
    />
  </div>
</template>
<script>
import { inject } from 'vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { confidenceToColourClass } from '../../store/helpers/mapping/confidenceThresholds';

export default {
  name: 'ConfidenceBars',
  props: {
    confidence: {
      type: Number,
      default: 0.0,
    },
  },
  setup() {
    // If injected key is not provided in the parent chain,
    // show confidence indicators by default
    const show = inject('showConfidenceIndicators', true);
    const enableUXRefresh = FEATURE_FLAGS.ENABLE_UX_REFRESH;
    return { show, enableUXRefresh };
  },
  computed: {
    confidenceBarColourClass() {
      return confidenceToColourClass(this.confidence);
    },
    tooltipText() {
      if (this.confidenceBarColourClass === 'confidence-low') {
        return 'Low confidence';
      }
      if (this.confidenceBarColourClass === 'confidence-medium') {
        return 'Medium confidence';
      }
      if (this.confidenceBarColourClass === 'confidence-high') {
        return 'High confidence';
      }
      return 'No value';
    },
  },
};
</script>
<style scoped lang="scss">
.confidence-bars-v2 {
  width: 25px;
  justify-content: center;

  .confidence-blank {
    background-color: #999 !important;
  }
  .confidence-bar-0, .confidence-bar-1, .confidence-bar-2 {
    display: block !important;
  }

  .confidence-bar-1 {
    &.confidence-low {
      background-color: #f2f2f2;
    }
  }

  .confidence-bar-2 {
    &.confidence-low,
    &.confidence-medium {
      background-color: #f2f2f2;
    }
  }

  &.hidden {
    background-color: #f2f2f2;
  }
}
</style>
