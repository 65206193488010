<template>
  <modal
    v-if="!enableUXRefresh"
    :show="show"
    :title="isUpdatingEntity ? 'Update Entity' : 'Add Entity Group'"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <div class="modal-content-add-new-entity">
        <div class="modal-content-spaced-container">
          <p> Select or create entity: </p>
          <label
            for="search-all-entities-add"
            class="modal-checkbox"
          >
            Include all entity types
            <input
              id="search-all-entities-add"
              v-model="searchAllEntities"
              type="checkbox"
            >
            <span class="checkmark" />
          </label>
        </div>
        <vue-multi-select
          id="entityRequestSelector"
          v-model="currentEntity"
          track-by="interDocumentEntityId"
          placeholder="Type to search for existing entities"
          open-direction="bottom"
          label="name"
          tag-placeholder="Add this as new entity"
          :options="entities"
          :multiple="false"
          :loading="getEntitiesIsLoading"
          :internal-search="false"
          :taggable="true"
          @tag="setNewCurrentEntity"
          @search-change="requestQueryEntitiesByName"
        >
          <template
            #noOptions
          >
            <span v-show="query !== ''">No entities found</span>
          </template>
        </vue-multi-select>
        <entity-detail :current-entity="currentEntity" />
      </div>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG_BORDERLESS"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          :message="isUpdatingEntity ? 'UPDATE' : 'ADD NEW GROUP'"
          :custom-classes="{'top-nav-item': true}"
          @onClick="onAddNewEntity"
        />
      </div>
    </template>
  </modal>
  <ModalRefresh
    v-else
    :show="show"
    max-width="480px"
    :show-overflow="true"
    :title="isUpdatingEntity ? 'Update Entity' : 'Add Entity Group'"
    @close="close"
  >
    <template #content>
      <div class="modal-refresh-body-text entity-modal-body">
        <div :style="{display: 'flex', 'flex-direction': 'column', gap: '16px'}">
          <form-field
            :block-level-form-input="true"
            label="Select or create entity:"
            class="form-field-v2"
          >
            <template #inputField>
              <vue-multi-select
                id="entityRequestSelector"
                v-model="currentEntity"
                class="multiselect-v2"
                track-by="interDocumentEntityId"
                placeholder="Type to search for existing entities"
                open-direction="bottom"
                label="name"
                tag-placeholder="Add this as new entity"
                :options="entities"
                :multiple="false"
                :loading="getEntitiesIsLoading"
                :internal-search="false"
                :taggable="true"
                @tag="setNewCurrentEntity"
                @search-change="requestQueryEntitiesByName"
              >
                <template
                  #noOptions
                >
                  <span v-show="query !== ''">No entities found</span>
                </template>
              </vue-multi-select>
            </template>
          </form-field>
          <CheckBoxInputV2
            id="include-all-entity-types"
            label="Include all entity types"
            :is-checked="searchAllEntities"
            @update-is-checked="() => searchAllEntities = !searchAllEntities"
          />
        </div>
        <entity-detail
          :current-entity="currentEntity"
          :v2="true"
        />
      </div>
    </template>
    <template #footer>
      <div class="modal-refresh-button-group">
        <ActionButtonRefresh
          text="Cancel"
          variant="TERTIARY"
          @on-click="close"
        />
        <ActionButtonRefresh
          :text="isUpdatingEntity ? 'Update' : 'Add New Group'"
          :is-disabled="!currentEntity"
          @on-click="onAddNewEntity"
        />
      </div>
    </template>
  </ModalRefresh>
</template>
<script>
import VueMultiSelect from 'vue-multiselect';
import { useToast } from 'vue-toastification';
import { getEntitiesByNameMatch } from '@/queries/entitiesQueries';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import { validateEntityName } from '@/store/helpers/request/validators/validateMetrics';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';
import EntityDetail from './EntityDetail.vue';
import ModalRefresh from './ModalRefresh.vue';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';
import CheckBoxInputV2 from '../forms/CheckBoxInputV2.vue';
import FormField from '../forms/FormField.vue';

const DEBOUNCE_WAIT_IN_MS = 700; // Set to allow for slowish API calls. Ideally we can move towards ~250ms

export default {
  components: {
    ActionButton,
    ModalRefresh,
    CheckBoxInputV2,
    Modal,
    ActionButtonRefresh,
    VueMultiSelect,
    EntityDetail,
    FormField,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    rowIdx: {
      type: Number,
      default: null,
    },
    metricFin: {
      type: String,
      default: '',
    },
    metricValidators: {
      type: Array,
      default: () => [],
    },
    initialValue: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'submitEntity'],
  data() {
    return {
      entities: [],
      currentEntity: '',
      getEntitiesIsLoading: false,
      toast: useToast(),
      query: '',
      searchAllEntities: false,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    ...mapGetters({
      idToken: 'authenticate/idToken',
    }),
    isUpdatingEntity() {
      return this.rowIdx === null;
    },
  },
  watch: {
    show(newShow) {
      if (newShow) {
        this.resetInputs();
      }
    },
    initialValue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setInitialEntity(newVal);
        }
      },
    },
  },
  methods: {
    requestQueryEntitiesByName(query) {
      this.query = query;
      if (query === '') {
        this.entities = [];
        this.getEntitiesIsLoading = false;
        return;
      }
      this.getEntitiesIsLoading = true;
      this.$log.info('getEntitiesByNameMatch:', query);
      this.queryEntitiesByName(query);
    },
    // Use a function declaration to scope `this` correctly
    queryEntitiesByName: debounce(function (query) { // eslint-disable-line func-names
      this.$log.info('Called debounced queryEntitiesByName...');
      this.getEntitiesIsLoading = true;
      return getEntitiesByNameMatch(this.idToken, {
        name: query,
        fin: this.searchAllEntities ? null : this.metricFin,
        include_entity_datapoints: true,
      })
        .then((r) => {
          this.$log.info('Entities:', r);
          this.entities = r.entities;
        })
        .catch((e) => {
          this.$log.error(e);
          this.toast.error(errorMessages.FAILED_ENTITY_SEARCH);
        })
        .finally(() => {
          this.getEntitiesIsLoading = false;
        });
    }, DEBOUNCE_WAIT_IN_MS),
    close() {
      this.$emit('close');
    },
    resetInputs() {
      if (!this.isUpdatingEntity || this.initialValue === '') {
        this.currentEntity = '';
      }
      this.query = '';
    },
    onAddNewEntity() {
      let emittedNewEntity = '';
      let entityDatapoints = [];
      if (this.currentEntity !== '') {
        emittedNewEntity = this.currentEntity.name;
        entityDatapoints = this.currentEntity.entityDatapoints;
      }
      try {
        validateEntityName(emittedNewEntity, this.metricValidators);
      } catch (e) {
        this.toast.error(e.message);
        return;
      }

      this.$emit('submitEntity', { newEntityName: emittedNewEntity, rowIdx: this.rowIdx, entityDatapoints });
    },
    clearEntitySelect() {
      this.currentEntity = '';
    },
    /**
     * Query entities by the name of the initial entities and choose the first
     * one returned with the same name.
     * Otherwise set the entity as new.
     */
    setInitialEntity(name) {
      this.$log.info('Searching for initial entity: ', name);
      getEntitiesByNameMatch(this.idToken, { name, include_entity_datapoints: true })
        .then((r) => {
          const foundEntity = r.entities.find((obj) => obj.name === name);
          if (foundEntity !== undefined) {
            this.currentEntity = {
              entityDatapoints: [],
              ...foundEntity,
            };
          } else {
            this.setNewCurrentEntity(name);
          }
        })
        .catch(() => {
          this.$log.error('Searching for initial entity failed: ', name);
          this.setNewCurrentEntity(name);
        });
    },
    setNewCurrentEntity(name) {
      this.currentEntity = {
        name,
        fins: [],
        clientIdentifier: null,
        interDocumentEntityId: 'new',
        entityDatapoints: [],
      };
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
<style lang="scss" scoped>
.entity-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
