import formatNumber from '@/store/helpers/formatNumber';
import toNumber from '@/store/helpers/toNumber';

/**
 * Validation check to make sure metric contains data.
 * @param {*} metric
 * @returns {bool} True if it contains data
 */
const checkMetricContainsData = (metric) => {
  const dpValue = metric.datapoints[0].value;
  return (
    (dpValue !== null && dpValue !== '') // Case for any normal cell
    || (
      typeof metric.linkedEntity !== 'undefined'
      && metric.linkedEntity !== null
      && metric.linkedEntity.selectedEntityId !== null
    ) // Case for when ENTITY_LINK type column
  );
};

const validatorHasOptions = (validator) => validator.type === 'INCLUDE' && 'options' in validator;

/**
 * Get options to display in the dropdown from the validators.
 * @returns {*} null if no options, list of string options otherwise.
 */
const getNumericValidatorOptions = (validators) => {
  const result = [];
  if (validators.length) {
    validators.forEach((va) => {
      if (validatorHasOptions(va)) {
        va.options.forEach((option) => {
          result.push(formatNumber(toNumber(option), { thousandSeparator: ',' }));
        });
      }
    });
  }
  return result.length ? result : null;
};

export { checkMetricContainsData, getNumericValidatorOptions, validatorHasOptions };
