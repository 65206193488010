<template>
  <modal
    v-if="!enableUXRefresh"
    :show="show"
    title="Change Currency"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p class="">
        Change the currency of the entire table to:
      </p>
      <select-input
        v-model="selectedCurrency"
        select-id="change-table-currency-selector"
        name="change currency for table"
        :options="currencyOptions"
      />
      <br>
      <label
        for="currency-table-checkbox"
        class="modal-checkbox"
      >
        Preserve new currency for further changes
        <input
          id="currency-table-checkbox"
          v-model="preserveTableCurrency"
          type="checkbox"
        >
        <span class="checkmark" />
      </label>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG_BORDERLESS"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          message="CHANGE"
          :custom-classes="{'top-nav-item': true}"
          :is-disabled="selectedCurrency === ''"
          @onClick="onChangeCurrency"
        />
      </div>
    </template>
  </modal>
  <ModalRefresh
    v-else
    title="Change Currency"
    :show="show"
    max-width="480px"
    :show-overflow="true"
    @close="close"
  >
    <template #content>
      <div class="currency-modal-body">
        <div :style="{display: 'flex', 'flex-direction': 'column', gap: '4px'}">
          <form-field
            :block-level-form-input="true"
            label="Change the currency of the entire table to:"
            class="form-field-v2"
          >
            <template #inputField>
              <Multiselect
                class="multiselect-v2"
                :model-value="selectedCurrencyV2"
                :options="currencyOptionsV2"
                placeholder="-- Select Option --"
                track-by="id"
                label="label"
                @update:model-value="selectCurrency"
              />
            </template>
          </form-field>
        </div>
        <div class="modal-refresh-body-text preserve-currency-checkbox-input">
          <CheckBoxInputV2
            id="preserve-currency"
            label="Preserve new value for further changes"
            :is-checked="preserveTableCurrency"
            @update-is-checked="() => preserveTableCurrency = !preserveTableCurrency"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-refresh-button-group">
        <ActionButtonRefresh
          text="Cancel"
          variant="TERTIARY"
          @on-click="close"
        />
        <ActionButtonRefresh
          text="Continue"
          :is-disabled="selectedCurrencyV2 === null"
          @on-click="confirm"
        />
      </div>
    </template>
  </ModalRefresh>
</template>
<script>
import { currencyMappingToDisplayOption } from '@/store/helpers/mapping/currencies';
import SelectInput from '@/components/forms/SelectInput.vue';
import Multiselect from 'vue-multiselect';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';
import ModalRefresh from './ModalRefresh.vue';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';
import CheckBoxInputV2 from '../forms/CheckBoxInputV2.vue';
import FormField from '../forms/FormField.vue';

export default {
  components: {
    SelectInput,
    ActionButton,
    ModalRefresh,
    Modal,
    ActionButtonRefresh,
    Multiselect,
    CheckBoxInputV2,
    FormField,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirmUpdateTableCurrency'],
  data() {
    return {
      selectedCurrency: 'AUTO',
      selectedCurrencyV2: null,
      preserveTableCurrency: false,
      currencyOptionsV2: Object.keys(currencyMappingToDisplayOption).map((key) => ({ id: key, label: currencyMappingToDisplayOption[key] })),
      currencyOptions: currencyMappingToDisplayOption,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  methods: {
    reset() {
      this.selectedCurrency = 'AUTO';
      this.preserveTableCurrency = false;
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    confirm() {
      this.$emit('confirmUpdateTableCurrency', this.selectedCurrencyV2.id, this.preserveTableCurrency);
      this.reset();
    },
    selectCurrency(c) {
      this.selectedCurrencyV2 = c;
    },
    onChangeCurrency() {
      this.$emit('confirmUpdateTableCurrency', this.selectedCurrency, this.preserveTableCurrency);
      this.reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.currency-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
