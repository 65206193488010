const verifyMenuActions = {
  ADD_NEW_ROW_ABOVE: 'ADD_NEW_ROW_ABOVE',
  ADD_NEW_ROW_BELOW: 'ADD_NEW_ROW_BELOW',
  DELETE_SELECTED_ROW: 'DELETE_SELECTED_ROW',
  SAVE: 'SAVE',
  FULL_SAVE: 'FULL_SAVE',
  TRANSPOSE_TABLE: 'TRANSPOSE_TABLE',
  TOGGLE_CONFIDENCE_INDICATORS: 'TOGGLE_CONFIDENCE_INDICATORS',
  UPDATE_TABLE_CURRENCY: 'UPDATE_TABLE_CURRENCY',
  INCREASE_TABLE_SCALE: 'INCREASE_TABLE_SCALE',
  DECREASE_TABLE_SCALE: 'DECREASE_TABLE_SCALE',
  UNDO: 'UNDO',
  REDO: 'REDO',
  BREADCRUMB_SWITCH: 'BREADCRUMB_SWITCH',
  VERIFY_TABLE: 'VERIFY_TABLE',
  VERIFY_ROW: 'VERIFY_ROW',
  VERIFY_COLUMN: ' VERIFY_COLUMN',
};

export default verifyMenuActions;
