import isSet from '@/store/helpers/isSet';
import toNumber from '@/store/helpers/toNumber';
import { logger } from '@/store/logger';

export default (annotation) => {
  if (isSet(annotation.nv) && isSet(annotation.nv.n)) {
    const value = annotation.nv.n.v;
    if (isSet(value)) {
      return value;
    }
  }
  logger.warn('No normalised value found for annotation');
  const casted = toNumber(annotation.t);

  return Number.isNaN(casted) ? null : casted;
};
