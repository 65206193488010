<template>
  <component
    :is="rootElementType"
    class="table-cell table-cell-dropdown"
    :class="Object.assign(customClasses, {'is-selected': isCellSelected, 'is-highlighted': isCellHighlighted})"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified && !hideVerifiedStatus}"
    >
      <div class="table-cell-content">
        <div
          v-if="showConfidence && showConfidenceBars && (enableUXRefresh ? true : !isVerified)"
          class="left-of-input"
        >
          <confidence-bars :confidence="confidence" />
        </div>
        <vue-multi-select
          v-if="!readOnlyMode"
          :class="{'multiselect-v2': enableUXRefresh }"
          :model-value="selectValue"
          :options="entityOptions"
          track-by="id"
          label="name"
          placeholder="Entity Name"
          :allow-empty="true"
          group-values="entityMetrics"
          group-label="groupDisplay"
          :group-select="false"
          @update:model-value="updateValue"
          @open="onCellSelected"
        />
        <div
          v-else
          class="cell-content table-cell-content-input"
        >
          <span>{{ selectValue.name }}</span>
        </div>
        <metric-verify-toggle
          v-if="enableUXRefresh"
          :v2="true"
          :show-toggle="isCellSelected && !hideVerifiedStatus && canShowVerifyToggle"
          :is-verified="isVerified"
          @toggleCellVerified="onVerifyToggled"
        />
        <div
          v-if="enableUXRefresh"
          class="right-of-input metric-info-icon"
        />
      </div>
      <metric-verify-toggle
        v-if="!enableUXRefresh"
        :show-toggle="isCellSelected && !hideVerifiedStatus && canShowVerifyToggle"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </component>
</template>
<script>
import VueMultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import isSet from '@/store/helpers/isSet';
import SelectInput from '@/components/forms/SelectInput.vue';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import isObject from '@/store/helpers/isObject';
import ConfidenceBars from '@/components/general/ConfidenceBars.vue';
import objHasKey from '@/store/helpers/objHasKey';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { inject } from 'vue';

const entityLinkCellValueValidator = (propValue) => (
  isObject(propValue) && objHasKey(propValue, 'selectedEntityId')
) || propValue === null || propValue === undefined;

export default {
  components: {
    SelectInput, ConfidenceBars, MetricVerifyToggle, VueMultiSelect,
  },
  props: {
    confidence: {
      type: Number,
      default: null,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    hideVerifiedStatus: {
      type: Boolean,
      default: false,
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    selectedEntity: {
      validator: entityLinkCellValueValidator,
      required: true,
    },
    linkGroupIdOptions: { // Array of GroupIdentifier strings that this EntityLinkCell can link to
      type: Array,
      required: true,
    },
    rootElementType: {
      type: String,
      default: 'td',
    },
    showConfidence: {
      type: Boolean,
      default: true,
    },
    canShowVerifyToggle: {
      type: Boolean,
      default: true,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'selectedEntityUpdated'],
  setup() {
    const showConfidenceBars = inject('showConfidenceIndicators', true);
    return { showConfidenceBars };
  },
  data() {
    return {
      value: null,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    ...mapGetters({
      entityNameMetrics: 'entityNameMetrics/entityNameMetrics',
      entityNameMetricsForOptionSelect: 'entityNameMetrics/entityNameMetricsForOptionSelect',
    }),
    selectValue() {
      const selectedEntityId = this.value?.selectedEntityId;
      if (!isSet(selectedEntityId)) {
        return {
          selectedEntityId: null,
        };
      }
      for (const g of this.entityOptions) { // eslint-disable-line no-restricted-syntax
        for (const m of g.entityMetrics) { // eslint-disable-line no-restricted-syntax
          if (m.id === selectedEntityId) {
            return m;
          }
        }
      }
      this.$log.warn(`Unable to find ${selectedEntityId}.`);
      return {
        selectedEntityId: null,
      };
    },
    entityOptions() {
      return this.entityNameMetricsForOptionSelect(this.linkGroupIdOptions);
    },
  },
  watch: {
    selectedEntity: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal?.selectedEntityId !== oldVal?.selectedEntityId) {
          this.value = newVal;
        }
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.$log.info('updateValue:', newValue);
      if (newValue === null) {
        this.value = {
          selectedEntityId: null,
        };
      } else {
        this.value = {
          selectedEntityId: newValue.id,
        };
      }
      this.$emit('selectedEntityUpdated', this.value);
    },
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    onValueInputBlur() {
      this.$log.info('cell blurred');
    },
    onUpdateValue() {
      this.$log.info('value updated');
    },
  },
};
</script>
  <style src="vue-multiselect/dist/vue-multiselect.css">
</style>
