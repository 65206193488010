import Big from 'big.js';
import toNumber from '@/store/helpers/toNumber';

export default (values) => {
  let total = new Big(0);
  values.forEach((v) => {
    if (v !== null) {
      total = total.add(new Big(v));
    }
  });

  return toNumber(total.toPrecision(15));
};
