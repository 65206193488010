<template>
  <component
    :is="rootElementType"
    class="table-cell"
    :class="Object.assign(
      customClasses,
      {
        'is-selected': isCellSelected,
        'is-highlighted': isCellHighlighted,
        'table-cell-dropdown': validatorOptions
      }
    )"
    @click="onCellSelected"
  >
    <div
      class="table-cell-container"
      :class="{'is-verified': isVerified && !hideVerifiedStatus}"
    >
      <div class="table-cell-content">
        <div
          v-if="!!$slots['left-of-input']"
          class="left-of-input"
        >
          <slot name="left-of-input" />
        </div>
        <div
          v-if="showConfidence && showConfidenceBars && (enableUXRefresh ? true : !isVerified)"
          class="left-of-input"
        >
          <confidence-bars :confidence="value ? confidence : 0" />
        </div>
        <vue-multi-select
          v-if="validatorOptions"
          :model-value="value"
          :options="validatorOptions"
          :class="{'multiselect-v2': enableUXRefresh}"
          placeholder="Not Reported"
          :disabled="isReadOnly"
          @update:model-value="updateOptions"
          @open="onCellSelected"
        />
        <input
          v-else-if="!isMultiLine"
          :style="{'min-width': '280px'}"
          type="text"
          class="text-cell-input cell-content cell-align-right"
          placeholder="Not Reported"
          :value="value"
          :readonly="isReadOnly"
          @input="updateValue($event.target.value)"
          @blur="onValueInputBlur"
        >
        <div
          v-else
          class="cell-content table-cell-content-input"
        >
          <span>{{ value }}</span>
        </div>
        <alert-circle-icon
          v-if="validationError"
          v-tooltip="`${validationError}`"
          size="1.2x"
          stroke="red"
        />
        <metric-verify-toggle
          v-if="enableUXRefresh"
          :v2="true"
          :show-toggle="isCellSelected && !hideVerifiedStatus && canShowVerifyToggle"
          :is-verified="isVerified"
          @toggleCellVerified="onVerifyToggled"
        />
        <div
          v-if="enableUXRefresh ? !!$slots['right-of-input'] : true"
          class="right-of-input"
        >
          <slot name="right-of-input" />
        </div>
        <div
          v-if="enableUXRefresh && !hideInfoIcon"
          class="right-of-input metric-info-icon"
        />
      </div>
      <metric-verify-toggle
        v-if="!enableUXRefresh"
        :show-toggle="isCellSelected && !hideVerifiedStatus && canShowVerifyToggle"
        :is-verified="isVerified"
        @toggleCellVerified="onVerifyToggled"
      />
    </div>
  </component>
</template>
<script>
import { AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import VueMultiSelect from 'vue-multiselect';
import MetricVerifyToggle from '@/components/verify/MetricVerifyToggle.vue';
import isString from '@/store/helpers/isString';
import ConfidenceBars from '@/components/general/ConfidenceBars.vue';
import { validateMetric } from '@/store/helpers/request/validators/validateMetrics';
import ValidationException from '@/store/helpers/request/exceptions/ValidationException';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { inject } from 'vue';

export default {
  components: {
    ConfidenceBars, MetricVerifyToggle, VueMultiSelect, AlertCircleIcon,
  },
  props: {
    confidence: {
      type: Number,
      default: null,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    hideInfoIcon: {
      type: Boolean,
      default: false,
    },
    hideVerifiedStatus: {
      type: Boolean,
      default: false,
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    isMultiLine: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      validator: (propValue) => isString(propValue) || propValue === null,
      required: true,
    },
    rootElementType: {
      type: String,
      default: 'td',
    },
    showConfidence: {
      type: Boolean,
      default: true,
    },
    canShowVerifyToggle: {
      type: Boolean,
      default: true,
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'update:modelValue'],
  setup() {
    const showConfidenceBars = inject('showConfidenceIndicators', true);
    return { showConfidenceBars };
  },
  data() {
    return {
      value: null,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    validatorOptions() {
      let result = null;
      if (this.validators.length) {
        this.validators.forEach((va) => {
          if (va.type === 'INCLUDE' && 'options' in va) {
            result = va.options;
          }
        });
      }
      return result;
    },
    validationError() {
      if (this.valueChanged()) {
        try {
          validateMetric(this.value, this.validators);
        } catch (e) {
          if (e instanceof ValidationException) {
            return e.message;
          }
          throw e;
        }
      }
      return null;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.value = newVal;
        }
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.value = newValue;
    },
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    valueChanged() {
      return this.value !== this.modelValue;
    },
    onValueInputBlur() {
      if (this.valueChanged() && !this.validationError) {
        this.$emit('update:modelValue', this.value);
      }
    },
    updateOptions(newValue) {
      this.updateValue(newValue);
      this.onValueInputBlur();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
