<template>
  <div
    class="top-nav-groups"
    :class="{...customClasses, 'breadcrumb-nav-v2': enableUXRefresh, 'freyda-scrollbar-thin': enableUXRefresh}"
  >
    <div class="left-group">
      <breadcrumbs
        :current-breadcrumb-index="currentBreadcrumbIndex"
        :breadcrumbs="breadcrumbs"
        :document-request-id="documentRequestId"
      />
    </div>
    <div
      class="right-group"
      style="margin-left: auto"
    >
      <alternative-answers />
      <div
        v-if="!enableUXRefresh"
        class="top-nav-item icon-container maximize-icon-container"
        @click="onPopoutToggled"
      >
        <minimize2-icon
          v-if="documentIsPoppedOut"
          v-tooltip="'Show document in this window'"
          size="1.5x"
          class="custom-class"
        />
        <maximize2-icon
          v-else
          v-tooltip="'Open document in a new window'"
          size="1.5x"
          class="custom-class"
        />
      </div>
      <ActionButtonRefresh
        v-else
        v-tooltip="documentIsPoppedOut ? 'Show document in this window' : 'Open document in a new window'"
        :icon-only="true"
        variant="TERTIARY"
        size="xs"
        @on-click="onPopoutToggled"
      >
        <template #icon>
          <minimize2-icon
            v-if="documentIsPoppedOut"
          />
          <maximize2-icon
            v-else
          />
        </template>
      </ActionButtonRefresh>
    </div>
  </div>
</template>
<script>
import { Maximize2Icon, Minimize2Icon } from '@zhuowenli/vue-feather-icons';
import Breadcrumbs from '@/components/verify/Breadcrumbs.vue';
import AlternativeAnswers from '@/components/verify/AlternativeAnswers.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';

export default {
  components: {
    AlternativeAnswers, Breadcrumbs, Maximize2Icon, Minimize2Icon, ActionButtonRefresh,
  },
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    currentBreadcrumbIndex: {
      type: Number,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    documentIsPoppedOut: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['popOutToggled'],
  data() {
    return {
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  methods: {
    onPopoutToggled() {
      this.$emit('popOutToggled');
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb-nav-v2 {
  width: 100% !important;
  min-height: unset !important;
  padding-top: 8px;
  display: flex;
  gap: 8px;

  &.top-nav-groups {
    border-bottom: var(--Stroke-width-Width-1, 1px) solid var(--stroke-_BrandSecondary, #f2f2f2);
    background: var(--background-System-_primary, #FFF);
  }

  .left-group {
    padding: 0;
    flex: 1 1 auto;
    overflow-x: auto;
  }

  .right-group {
    padding-right: 12px;
    flex: 1 1 auto;
  }
}
</style>
