<template>
  <section class="metric-container">
    <metric-table-container
      :document-request-id="documentRequestId"
      :initial-table="initialTable"
      :table-idx="tableIdx"
      :breadcrumb-idx="breadcrumbIdx"
      :is-repeating="isTableRepeating"
      :verify-menu-action-triggers="activeVerifyMenuActionTriggers"
      :has-calculated-totals="hasCalculatedTotals"
      :default-transposed="defaultTransposed"
    />
  </section>
</template>
<script>
import MetricTableContainer from '@/components/verify/tableLayout/MetricTableContainer.vue';
import verifyMenuActions from '@/store/helpers/mapping/verifyMenuActions';

export default {
  components: { MetricTableContainer },
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    initialTable: {
      type: Object,
      required: true,
    },
    tableIdx: {
      type: Number,
      required: true,
    },
    breadcrumbIdx: {
      type: Number,
      required: true,
    },
    isTableRepeating: {
      type: Boolean,
      required: true,
    },
    verifyMenuActionTriggers: {
      type: Object,
      required: true,
    },
    hasCalculatedTotals: {
      type: Boolean,
      required: true,
    },
    defaultTransposed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeVerifyMenuActionTriggers: {},
    };
  },
  computed: {
    isActive() {
      return this.$route.params.breadcrumbIndex === String(this.breadcrumbIdx);
    },
  },
  watch: {
    verifyMenuActionTriggers: {
      handler(newTriggers, oldTriggers) {
        this.$log.info('verifyMenuActionTriggers changed. New:', newTriggers, 'Old:', oldTriggers);
        Object.keys(newTriggers).forEach((t) => {
          this.activeVerifyMenuActionTriggers[t] = newTriggers[t];
        });
      },
      immediate: true,
      deep: true,
    },
    isActive(newIsActive) {
      if (!newIsActive) {
        this.$log.info('Table idx', this.tableIdx, 'deactivated (auto-saving)');
        this.activeVerifyMenuActionTriggers[verifyMenuActions.SAVE] += 0.000001;
        this.activeVerifyMenuActionTriggers[verifyMenuActions.BREADCRUMB_SWITCH] += 0.000001;
      }
    },
  },
};
</script>
