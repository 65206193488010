<template>
  <table class="transposed">
    <tbody>
      <tr
        v-for="(colHeader, colHeaderIdx) in columnHeaders"
        v-show="showColumnIndices[colHeaderIdx]"
        :key="colHeaderIdx"
      >
        <metric-table-column-header
          :custom-classes="{'sticky-left': true, 'row-header': true}"
          :header-text="colHeader.text"
          :header-description="colHeader.description"
          :header-options="columnHeaderOptions[colHeaderIdx]"
          :is-selected="selectedHeaders[colHeaderIdx]"
          :is-verified="columnVerificationFlags[colHeaderIdx]"
          root-element-type="td"
          :show-column-options="colHeader.metricType === 'CURRENCY'"
          :can-show-verify-toggle="!((colHeaderIdx === 0 && colHeader.metricType === 'ENTITY_NAME') || colHeader.metricType === 'AKA')"
          @headerSelected="onHeaderSelected(colHeaderIdx)"
          @verifyToggled="onToggleHeaderVerified(colHeaderIdx)"
          @currencyChanged="onCurrencyChanged(colHeaderIdx)"
          @increaseScale="onAdjustScale(colHeaderIdx, 3)"
          @decreaseScale="onAdjustScale(colHeaderIdx, -3)"
          @numericFormatChanged="onNumericFormatChanged(colHeaderIdx)"
        />
        <template
          v-for="(metricIdx, rowIdx) in tableRows.map(r => r[colHeaderIdx]) "
          :key="rowIdx"
        >
          <entity-name-cell
            v-if="metrics[metricIdx].metricType === 'ENTITY_NAME'"
            v-show="showRowIndices[rowIdx]"
            :metric-data="metrics[metricIdx]"
            :is-cell-selected="selectedRowHeaders[rowIdx]"
            :is-verified="metrics[metricIdx].isVerified"
            :is-repeating="isRepeating"
            :is-row-header="metricIdxIsRowEntityHeader(metricIdx, colHeaderIdx)"
            root-element-type="th"
            @headerSelected="onRowHeaderSelected(rowIdx, colHeaderIdx)"
            @toggleHeaderVerified="onToggleCellVerified(rowIdx, colHeaderIdx)"
            @addNewRowBelow="onAddNewRowAt(rowIdx + 1)"
            @addNewRowAbove="onAddNewRowAt(rowIdx)"
            @deleteRow="onDeleteRowAt(rowIdx)"
            @renameEntity="onRenameEntityAt(
              rowIdx, colHeaderIdx, metrics[metricIdx].datapoints[0].value, metrics[metricIdx].fin, metrics[metricIdx].validators
            )"
            @removeEntity="onRemoveEntity(colHeaderIdx)"
          />
          <metric-table-data-cell
            v-else
            :metric-data="metrics[metricIdx]"
            :is-cell-highlighted="isCellHighlighted(rowIdx, colHeaderIdx)"
            :is-cell-selected="isCellSelected(rowIdx, colHeaderIdx)"
            :show-confidence="true"
            :entity-link-options="colHeader.entityLinkGIOptions"
            :is-verified="isCellVerified(metricIdx)"
            @cellSelected="onCellSelected(rowIdx, colHeaderIdx)"
            @toggleCellVerified="onToggleCellVerified(rowIdx, colHeaderIdx)"
            @cellValueUpdated="onCellValueUpdated(rowIdx, colHeaderIdx, $event)"
            @cellCurrencyValueUpdated="onCellCurrencyValueUpdated(rowIdx, colHeaderIdx, $event)"
            @cellAkasUpdated="onCellAkasValueUpdated(rowIdx, colHeaderIdx, $event)"
            @selectedEntityUpdated="onCellSelectedEntityUpdated(rowIdx, colHeaderIdx, $event)"
          />
        </template>
        <metric-table-calculated-total-cell
          v-if="hasCalculatedTotals"
          v-show="showCalculatedTotals"
          :value="calculatedTotals[colHeaderIdx]"
          :is-verified="false"
          :custom-classes="calculatedTotalCustomClasses(colHeaderIdx)"
          :cell-content-custom-classes="{'numeric-cell-content': colHeaderIdx !== 0}"
          :override-display-value="colHeaderIdx === 0 ? 'Calculated Totals' : ''"
          :root-element-type="colHeaderIdx === 0 && hasEntityNameRowHeaders ? 'th' : 'td'"
        />
      </tr>
    </tbody>
    <div v-if="columnHeaders.length === 0">
      <p class="hint">
        Loading metrics...
      </p>
    </div>
    <empty-metric-table-row
      v-else-if="!rows.length"
      @addNewRow="onAddNewRowAt(0)"
    />
  </table>
</template>
<script>
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import MetricTableCalculatedTotalCell from '@/components/verify/cellTypes/MetricTableCalculatedTotalCell.vue';
import MetricTableDataCell from '@/components/verify/cellTypes/MetricTableDataCell.vue';
import EmptyMetricTableRow from '@/components/verify/tableLayout/EmptyMetricTableRow.vue';
import MetricTableColumnHeader from '@/components/verify/tableLayout/MetricTableColumnHeader.vue';
import EntityNameCell from '@/components/verify/tableLayout/EntityNameCell.vue';

export default {
  components: {
    MetricTableCalculatedTotalCell,
    EmptyMetricTableRow,
    EntityNameCell,
    MetricTableColumnHeader,
    MetricTableDataCell,
  },
  props: {
    calculatedTotals: {
      type: Array,
      required: true,
    },
    columnHeaderOptions: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Array,
      required: true,
    },
    columnVerificationFlags: {
      type: Array,
      required: true,
    },
    hasEntityNameRowHeaders: {
      type: Boolean,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },
    numVisibleColumns: {
      type: Number,
      required: true,
    },
    numVisibleRows: {
      type: Number,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    showRowIndices: {
      type: Array,
      required: true,
    },
    selectedCells: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
    selectedRowHeaders: {
      type: Array,
      required: true,
    },
    showCalculatedTotals: {
      type: Boolean,
      required: true,
    },
    showColumnIndices: {
      type: Array,
      required: true,
    },
    tableIdx: {
      type: Number,
      required: true,
    },
    isRepeating: {
      type: Boolean,
      required: true,
    },
    hasCalculatedTotals: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'cellSelected',
    'toggleCellVerified',
    'cellValueUpdated',
    'cellCurrencyValueUpdated',
    'cellAkasUpdated',
    'addNewRowAt',
    'headerSelected',
    'headerCurrencyChanged',
    'headerAdjustScale',
    'headerNumericFormatChanged',
    'verifyHeaderToggled',
    'rowHeaderSelected',
    'deleteRowAt',
    'cellSelectedEntityUpdated',
    'renameEntityAt',
    'removeEntity',
    'setLoading',
  ],
  data() {
    return {
      displayedRowsCount: null,
      infiniteScrollActivationThreshold: 200,
      increment: 5,
      resizeObserver: null,
      infiniteScrollContainer: null,
    };
  },
  computed: {
    enableInfiniteScroll() {
      return FEATURE_FLAGS.METRICS_INFINITE_SCROLL;
    },
    tableRows() {
      return this.enableInfiniteScroll ? this.rows.slice(0, this.displayedRowsCount) : this.rows;
    },
  },
  watch: {
    numVisibleRows: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.$log.debug(`Number of rows changed from ${oldVal} to ${newVal}`);
        if (!this.enableInfiniteScroll || this.displayedRowsCount >= this.rows.length) { return; }
        if (newVal > oldVal) {
          const numAddedRows = newVal - oldVal;
          this.$log.debug(`Loading ${numAddedRows} more rows to cover row decrease from ${oldVal} to ${newVal}`);
          this.loadMoreRows(numAddedRows);
        }
      },
    },
  },
  mounted() {
    // If feature flag disabled, just render all rows as usual.
    if (!this.enableInfiniteScroll) {
      this.$log.debug('Infinite scroll - not enabled, displaying all rows');
      this.displayedRowsCount = this.rows.length;
      return;
    }
    // If number of rows is less than activation threshold, render all rows as usual.
    if (this.rows.length <= this.infiniteScrollActivationThreshold) {
      this.$log.debug('Infinite scroll - no. rows less than threshold, displaying all rows');
      this.displayedRowsCount = this.rows.length;
      this.$emit('setLoading', { isLoading: true, isOpaque: true });
      setTimeout(() => {
        this.displayedRowsCount = this.rows.length;
        this.$emit('setLoading', { isLoading: false });
      }, 0);
      return;
    }

    this.setupResizeObserver();
    this.loadInitialRows();
    this.setupInfiniteScrollEventHandler();
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    destroy() {
      this.resizeObserver?.disconnect();
      this.infiniteScrollContainer?.removeEventListener('scroll', this.handleInfiniteScroll);
    },
    setupResizeObserver() {
      this.$log.debug('Setting up Resize observer for metrics table');
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.$log.debug('Resize observer for metrics table has been set up (not yet activated): ', this.resizeObserver);
    },
    loadInitialRows() {
      this.$log.debug('Calculating number of initial rows to display based on container size...');
      const initialRowCount = this.calculateMaxRowCount();
      this.$log.debug('Initializing table with ', initialRowCount, ' rows');
      this.$emit('setLoading', { isLoading: true, isOpaque: true });
      setTimeout(() => {
        this.loadMoreRows(initialRowCount);
        this.$emit('setLoading', { isLoading: false });
        this.$log.debug(this.displayedRowsCount, ' rows have been rendered');
        this.resizeObserver.observe(document.getElementById('infinite-scroll'));
        this.$log.debug('Resize observer has been activated for the metrics table #infinite-scroll container', this.resizeObserver);
      }, 0);
    },
    setupInfiniteScrollEventHandler() {
      this.infiniteScrollContainer = document.getElementById('infinite-scroll');
      this.infiniteScrollContainer.addEventListener('scroll', this.handleInfiniteScroll);
      this.$log.debug('Infinite-scroll event handler has been set up');
    },
    handleInfiniteScroll(event) {
      if (this.displayedRowsCount >= this.rows.length) { return; }
      const { scrollWidth, scrollLeft, clientWidth } = event.target;
      if (Math.abs(scrollWidth - clientWidth - scrollLeft) <= 128) {
        this.$emit('setLoading', { isLoading: true });
        setTimeout(() => {
          this.loadMoreRows(this.increment);
          this.$emit('setLoading', { isLoading: false });
        }, 0);
      }
    },
    onResize() {
      this.$log.debug('Infinite scroll container has changed size, computing number of rows to add if necessary.');
      const numRowsThatFit = this.calculateMaxRowCount();
      const numRowsToAdd = numRowsThatFit - this.displayedRowsCount;
      if (numRowsToAdd > 0) {
        this.$log.debug(`Loading ${numRowsToAdd} new rows to fill additional space`);
        this.$emit('setLoading', { isLoading: true });
        setTimeout(() => {
          this.loadMoreRows(numRowsToAdd);
          this.$emit('setLoading', { isLoading: false });
        }, 0);
      }
    },
    loadMoreRows(numRowsToAdd) {
      let numRows = numRowsToAdd;
      // Prevent loading more rows than currently exists
      if (this.displayedRowsCount + numRowsToAdd > this.rows.length) {
        numRows = this.rows.length - this.displayedRowsCount;
      }
      this.displayedRowsCount += numRows;
      this.$log.debug('Loaded ', numRows, ' more rows - total displayed: ', this.displayedRowsCount);
    },
    calculateMaxRowCount() {
      const minWidthOfRow = 128; // min-width css property for table cells is 128px
      const containerWidth = document.getElementById('infinite-scroll').offsetWidth;
      this.$log.debug('Max width of infinite scroll container (px): ', containerWidth);
      const numRowsThatCanFitInView = Math.ceil(containerWidth / minWidthOfRow);
      this.$log.debug('Calculated max no. rows that can fit in container: ', numRowsThatCanFitInView);
      if (numRowsThatCanFitInView >= this.rows.length) {
        this.$log.debug('Since there is enough space in container to fit all rows, returning total row count: ', this.rows.length);
        return this.rows.length;
      }
      return numRowsThatCanFitInView;
    },
    calculatedTotalCustomClasses(colIdx) {
      return {
        'sticky-top': colIdx === 0 && this.hasEntityNameRowHeaders,
        'sticky-right': true,
      };
    },
    isCellSelected(rowIdx, colIdx) {
      if (this.selectedCells[rowIdx][colIdx] === undefined) {
        this.$log.warn('An undefined rowIdx, colIdx', rowIdx, colIdx);
        return false;
      }
      return this.selectedCells[rowIdx][colIdx];
    },
    isCellHighlighted(rowIdx, colIdx) {
      return this.selectedHeaders[colIdx] || this.selectedRowHeaders[rowIdx];
    },
    isCellVerified(metricIdx) {
      const metric = this.metrics[metricIdx];
      if (metric.metricType === 'AKA') {
        // AKA cell always lives below a ENTITY_NAME cell.
        // If the ENTITY_NAME cell is verified, the aka cell is also verified.
        return this.metrics[metricIdx - 1].isVerified;
      }
      return metric.isVerified;
    },
    metricIdxIsRowEntityHeader(metricIdx, colIdx) {
      return colIdx === 0 && this.hasEntityNameRowHeaders;
    },
    headerCustomClasses(header, headerIdx) {
      return {
        'sticky-top-left': headerIdx === 0 && this.hasEntityNameRowHeaders,
        'sticky-left': headerIdx === 0 && this.hasEntityNameRowHeaders,
      };
    },
    onToggleHeaderVerified(headerIdx) {
      this.$emit('verifyHeaderToggled', headerIdx);
    },
    onCellSelected(rowIdx, colIdx) {
      this.$emit('cellSelected', rowIdx, colIdx);
    },
    onRowHeaderSelected(rowIdx, colIdx) {
      this.$emit('rowHeaderSelected', rowIdx, colIdx);
    },
    onToggleCellVerified(rowIdx, colIdx) {
      this.$emit('toggleCellVerified', rowIdx, colIdx);
    },
    onCellValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellValueUpdated', rowIdx, colIdx, newValue);
    },
    onCellCurrencyValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellCurrencyValueUpdated', rowIdx, colIdx, newValue, true, false);
    },
    onCellAkasValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellAkasUpdated', rowIdx, colIdx, newValue);
    },
    onCellSelectedEntityUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellSelectedEntityUpdated', rowIdx, colIdx, newValue);
    },
    onAddNewRowAt(rowIdx) {
      this.$emit('addNewRowAt', rowIdx);
    },
    onHeaderSelected(headerIdx) {
      this.$log.info('Transposed MT onHeaderSelected');
      this.$emit('headerSelected', headerIdx);
    },
    onVerifyToggled(headerIdx) {
      this.$emit('verifyHeaderToggled', headerIdx);
    },
    onCurrencyChanged(headerIdx) {
      this.$emit('headerCurrencyChanged', headerIdx);
    },
    onAdjustScale(headerIdx, scale) {
      this.$emit('headerAdjustScale', headerIdx, scale);
    },
    onNumericFormatChanged(headerIdx) {
      this.$emit('headerNumericFormatChanged', headerIdx);
    },
    onDeleteRowAt(rowIdx) {
      this.$emit('deleteRowAt', rowIdx);
    },
    onRenameEntityAt(rowIdx, headerIdx, rowValue, metricFin, metricValidators) {
      this.$emit('renameEntityAt', rowIdx, headerIdx, rowValue, metricFin, metricValidators);
    },
    onRemoveEntity(colIdx) {
      this.$emit('removeEntity', colIdx);
    },
  },
};
</script>
