import {
  createRowStateChange,
  realiseStateChange,
  updateAkaValueStateChange,
  updateConfidenceStateChange,
  updateCurrencyValueStateChange,
  updateLocationStateChange,
  updateSelectedEntityValueStateChange,
  updateValueStateChange,
  updateVerifyStateChange,
  cudTypes,
  stateChangeTypes,
} from '@/store/helpers/metricTableState/stateChange';

/**
 * --- Information ---
 *
 * Please refer to the undo/redo documentation:
 * https://freyda.myjetbrains.com/youtrack/articles/FP-A-189/Undo-Redo
 */

const resetRedoChanges = (store, redoChanges) => {
  redoChanges.splice(0, redoChanges.length);
  store.commit('verifyDocument/SET_CAN_REDO', false);
};

/**
 * Reverse state change to be used for undo.
 * @param {*} stateChange to be reversed.
 * @returns reversed 'stateChange'.
 */
const reverseStateChange = (stateChange) => {
  let reversedStateChange;
  if (stateChange.type === stateChangeTypes.ROW && stateChange.cud === cudTypes.DELETE) {
    reversedStateChange = createRowStateChange(stateChange.rowIdx, null, stateChange.oldVal);
  } else if (stateChange.type === stateChangeTypes.VERIFY) {
    reversedStateChange = updateVerifyStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.VALUE) {
    reversedStateChange = updateValueStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.CONFIDENCE) {
    reversedStateChange = updateConfidenceStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.LOCATION) {
    reversedStateChange = updateLocationStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.CURRENCY_VALUE) {
    reversedStateChange = updateCurrencyValueStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.AKA_VALUE) {
    reversedStateChange = updateAkaValueStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal, stateChange.singleChange,
    );
  } else if (stateChange.type === stateChangeTypes.ENTITY_LINK) {
    reversedStateChange = updateSelectedEntityValueStateChange(
      stateChange.metricId, stateChange.rowIdx, stateChange.colIdx, stateChange.newVal, stateChange.oldVal,
    );
  } else {
    throw Error(`Undo not implemented for: ${stateChange.type}, ${stateChange.cud}`);
  }
  return reversedStateChange;
};

/**
 * Undo last state change by removing the last element in the table state changes.
 * @returns ['stateChange' that was reverted, revered 'stateChange']
 */
const undoLastStateChange = (rows, metrics, columnHeaders, tableStateChanges) => {
  const lastStateChange = tableStateChanges.pop();
  const reversedStateChange = reverseStateChange(lastStateChange);
  realiseStateChange(reversedStateChange, rows, metrics, columnHeaders);
  return [lastStateChange, reversedStateChange];
};

/**
 * Check is state change is nested.
 * A nested state change recursively calls 'undo'.
 * A single state change does not need to call 'undo' again.
 * @returns {bool} True is change is nested;
 *                 False is change is single.
 */
const isNestedChange = (stateChange) => (
  (
    [
      stateChangeTypes.CONFIDENCE,
      stateChangeTypes.LOCATION,
      stateChangeTypes.CURRENCY_VALUE,
      stateChangeTypes.VALUE,
      stateChangeTypes.VERIFY,
      stateChangeTypes.AKA_VALUE,
    ].includes(stateChange.type)
  ) && (!stateChange.singleChange)
);

/**
 * When a new row is added, all the state changes in rows above that
 * need to have their row index incremented.
 */
const adjustForAddedRow = (tableStateChanges, redoChanges, rowIdx) => {
  tableStateChanges.forEach((state) => {
    if (state.rowIdx >= rowIdx) {
      state.rowIdx += 1;
    }
  });
  redoChanges.forEach((state) => {
    if (state.rowIdx >= rowIdx) {
      state.rowIdx += 1;
    }
  });
};

export {
  resetRedoChanges,
  undoLastStateChange,
  isNestedChange,
  reverseStateChange,
  adjustForAddedRow,
};
