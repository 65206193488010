<template>
  <tbody v-if="(enableInfiniteScroll && displayedRowsCount !== null) || !enableInfiniteScroll">
    <debug-section v-if="enableInfiniteScroll">
      <template #content>
        <pre>Number of rows: {{ rows?.length }} {{ numVisibleRows }}</pre>
        <pre>Number of displayed rows: {{ displayedRowsCount }}</pre>
        <pre>Increment value: {{ increment }}</pre>
        <pre>Infinite Scroll activation threshold: {{ infiniteScrollActivationThreshold }}</pre>
        <pre>Change increment: </pre>
        <input
          v-model="increment"
          class="text-input"
          type="number"
        >
      </template>
    </debug-section>
    <tr
      v-for="(row, rowIdx) in tableRows"
      v-show="showRowIndices[rowIdx]"
      :key="rowIdx"
    >
      <template
        v-for="(metricIdx, colIdx) in row"
        :key="colIdx"
      >
        <entity-name-cell
          v-if="metrics[metricIdx].metricType === 'ENTITY_NAME'"
          v-show="showColumnIndices[colIdx]"
          :metric-data="metrics[metricIdx]"
          :custom-classes="{
            'sticky-left': metricIdxIsRowEntityHeader(metricIdx, colIdx),
            'row-header': metricIdxIsRowEntityHeader(metricIdx, colIdx)
          }"
          :is-cell-selected="selectedRowHeaders[rowIdx]"
          :is-verified="metrics[metricIdx].isVerified"
          :is-repeating="isRepeating"
          :is-row-header="metricIdxIsRowEntityHeader(metricIdx, colIdx)"
          root-element-type="td"
          @headerSelected="onRowHeaderSelected(rowIdx, colIdx)"
          @toggleHeaderVerified="onToggleCellVerified(rowIdx, colIdx)"
          @addNewRowBelow="onAddNewRowAt(rowIdx + 1)"
          @addNewRowAbove="onAddNewRowAt(rowIdx)"
          @deleteRow="onDeleteRowAt(rowIdx)"
          @renameEntity="onRenameEntity(
            rowIdx, colIdx, metrics[metricIdx].datapoints[0].value, metrics[metricIdx].fin, metrics[metricIdx].validators
          )"
          @removeEntity="onRemoveEntity(colIdx)"
        />
        <metric-table-data-cell
          v-else
          v-show="showColumnIndices[colIdx]"
          :entity-link-options="columnHeaders[colIdx].entityLinkGIOptions"
          :metric-data="metrics[metricIdx]"
          :is-cell-highlighted="isCellHighlighted(rowIdx, colIdx)"
          :is-cell-selected="isCellSelected(rowIdx, colIdx)"
          :show-confidence="true"
          :is-verified="isCellVerified(metricIdx)"
          @cellSelected="onCellSelected(rowIdx, colIdx)"
          @toggleCellVerified="onToggleCellVerified(rowIdx, colIdx)"
          @cellValueUpdated="onCellValueUpdated(rowIdx, colIdx, $event)"
          @cellCurrencyValueUpdated="onCellCurrencyValueUpdated(rowIdx, colIdx, $event)"
          @cellAkasUpdated="onCellAkasValueUpdated(rowIdx, colIdx, $event)"
          @selectedEntityUpdated="onSelectedEntityUpdated(rowIdx, colIdx, $event)"
        />
      </template>
    </tr>
    <tr v-show="showCalculatedTotals">
      <template
        v-for="(_, colIdx) in numColumns"
        :key="colIdx"
      >
        <metric-table-calculated-total-cell
          v-if="hasCalculatedTotals"
          v-show="showColumnIndices[colIdx]"
          :value="calculatedTotals[colIdx]"
          :is-verified="false"
          :custom-classes="calculatedTotalCustomClasses(colIdx)"
          :cell-content-custom-classes="{'numeric-cell-content': colIdx !== 0}"
          :override-display-value="colIdx === 0 ? 'Calculated Totals' : ''"
        />
      </template>
    </tr>
  </tbody>
  <div v-if="!enableInfiniteScroll && numColumns === 0">
    <p class="hint">
      Loading metrics...
    </p>
  </div>
  <empty-metric-table-row
    v-else-if="!enableInfiniteScroll && !rows.length"
    @addNewRow="onAddNewRowAt(0)"
  />
</template>
<script>
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import MetricTableCalculatedTotalCell from '@/components/verify/cellTypes/MetricTableCalculatedTotalCell.vue';
import MetricTableDataCell from '@/components/verify/cellTypes/MetricTableDataCell.vue';
import EntityNameCell from '@/components/verify/tableLayout/EntityNameCell.vue';
import EmptyMetricTableRow from '@/components/verify/tableLayout/EmptyMetricTableRow.vue';
import DebugSection from '@/components/debug/DebugSection.vue';

export default {
  components: {
    EmptyMetricTableRow,
    MetricTableCalculatedTotalCell,
    EntityNameCell,
    MetricTableDataCell,
    DebugSection,
  },
  props: {
    calculatedTotals: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Array,
      required: true,
    },
    hasEntityNameRowHeaders: {
      type: Boolean,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },
    numVisibleColumns: {
      type: Number,
      required: true,
    },
    numVisibleRows: {
      type: Number,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    selectedCells: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
    selectedRowHeaders: {
      type: Array,
      required: true,
    },
    showCalculatedTotals: {
      type: Boolean,
      required: true,
    },
    showColumnIndices: {
      type: Array,
      required: true,
    },
    numColumns: {
      type: Number,
      required: true,
    },
    showRowIndices: {
      type: Array,
      required: true,
    },
    isRepeating: {
      type: Boolean,
      required: true,
    },
    hasCalculatedTotals: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'cellSelected',
    'toggleCellVerified',
    'cellValueUpdated',
    'cellCurrencyValueUpdated',
    'cellAkasUpdated',
    'addNewRowAt',
    'rowHeaderSelected',
    'deleteRowAt',
    'cellSelectedEntityUpdated',
    'renameEntityAt',
    'removeEntity',
    'setLoading',
  ],
  data() {
    return {
      displayedRowsCount: null,
      infiniteScrollActivationThreshold: 200,
      increment: 5,
      resizeObserver: null,
      infiniteScrollContainer: null,
    };
  },
  computed: {
    enableInfiniteScroll() {
      return FEATURE_FLAGS.METRICS_INFINITE_SCROLL;
    },
    tableRows() {
      return this.enableInfiniteScroll ? this.rows.slice(0, this.displayedRowsCount) : this.rows;
    },
  },
  watch: {
    numVisibleRows: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.$log.debug(`Number of rows changed from ${oldVal} to ${newVal}`);
        if (!this.enableInfiniteScroll || this.displayedRowsCount >= this.rows.length) { return; }
        if (newVal > oldVal) {
          const numAddedRows = newVal - oldVal;
          this.$log.debug(`Loading ${numAddedRows} more rows to cover row decrease from ${oldVal} to ${newVal}`);
          this.loadMoreRows(numAddedRows);
        }
      },
    },
  },
  mounted() {
    // If feature flag disabled, just render all rows as usual.
    if (!this.enableInfiniteScroll) {
      this.$log.debug('Infinite scroll - not enabled, displaying all rows');
      this.displayedRowsCount = this.rows.length;
      return;
    }
    // If number of rows is less than activation threshold, render all rows as usual.
    if (this.rows.length <= this.infiniteScrollActivationThreshold) {
      this.$log.debug('Infinite scroll - no. rows less than threshold, displaying all rows');
      this.displayedRowsCount = this.rows.length;
      this.$emit('setLoading', { isLoading: true, isOpaque: true });
      setTimeout(() => {
        this.displayedRowsCount = this.rows.length;
        this.$emit('setLoading', { isLoading: false });
      }, 0);
      return;
    }

    this.setupResizeObserver();
    this.loadInitialRows();
    this.setupInfiniteScrollEventHandler();
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    destroy() {
      this.resizeObserver?.disconnect();
      this.infiniteScrollContainer?.removeEventListener('scroll', this.handleInfiniteScroll);
    },
    setupResizeObserver() {
      this.$log.debug('Setting up Resize observer for metrics table');
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.$log.debug('Resize observer for metrics table has been set up (not yet activated): ', this.resizeObserver);
    },
    loadInitialRows() {
      this.$log.debug('Calculating number of initial rows to display based on container size...');
      const initialRowCount = this.calculateMaxRowCount();
      this.$log.debug('Initializing table with ', initialRowCount, ' rows');
      this.$emit('setLoading', { isLoading: true, isOpaque: true });
      setTimeout(() => {
        this.loadMoreRows(initialRowCount);
        this.$emit('setLoading', { isLoading: false });
        this.$log.debug(this.displayedRowsCount, ' rows have been rendered');
        this.resizeObserver.observe(document.getElementById('infinite-scroll'));
        this.$log.debug('Resize observer has been activated for the metrics table #infinite-scroll container', this.resizeObserver);
      }, 0);
    },
    setupInfiniteScrollEventHandler() {
      this.infiniteScrollContainer = document.getElementById('infinite-scroll');
      this.infiniteScrollContainer.addEventListener('scroll', this.handleInfiniteScroll);
      this.$log.debug('Infinite-scroll event handler has been set up');
    },
    handleInfiniteScroll(event) {
      if (this.displayedRowsCount >= this.rows.length) { return; }
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 30) {
        this.$emit('setLoading', { isLoading: true });
        setTimeout(() => {
          this.loadMoreRows(this.increment);
          this.$emit('setLoading', { isLoading: false });
        }, 0);
      }
    },
    onResize() {
      this.$log.debug('Infinite scroll container has changed size, computing number of rows to add if necessary.');
      const numRowsThatFit = this.calculateMaxRowCount();
      const numRowsToAdd = numRowsThatFit - this.displayedRowsCount;
      if (numRowsToAdd > 0) {
        this.$log.debug(`Loading ${numRowsToAdd} new rows to fill additional space`);
        this.$emit('setLoading', { isLoading: true });
        setTimeout(() => {
          this.loadMoreRows(numRowsToAdd);
          this.$emit('setLoading', { isLoading: false });
        }, 0);
      }
    },
    loadMoreRows(numRowsToAdd) {
      let numRows = numRowsToAdd;
      // Prevent loading more rows than currently exists
      if (this.displayedRowsCount + numRowsToAdd > this.rows.length) {
        numRows = this.rows.length - this.displayedRowsCount;
      }
      this.displayedRowsCount += numRows;
      this.$log.debug('Loaded ', numRows, ' more rows - total displayed: ', this.displayedRowsCount);
    },
    calculateMaxRowCount() {
      const minHeightOfRow = 30; // min-height css property is set for all cells to be 30px
      const containerHeight = document.getElementById('infinite-scroll').offsetHeight;
      this.$log.debug('Max height of infinite scroll container (px): ', containerHeight);
      const numRowsThatCanFitInView = Math.ceil(containerHeight / minHeightOfRow);
      this.$log.debug('Calculated max no. rows that can fit in container: ', numRowsThatCanFitInView);
      if (numRowsThatCanFitInView >= this.rows.length) {
        this.$log.debug('Since there is enough space in container to fit all rows, returning total row count: ', this.rows.length);
        return this.rows.length;
      }
      return numRowsThatCanFitInView;
    },
    calculatedTotalCustomClasses(colIdx) {
      return {
        'sticky-left': colIdx === 0 && this.hasEntityNameRowHeaders,
        'sticky-bottom': true,
        'row-header': colIdx === 0,
      };
    },
    metricIdxIsRowEntityHeader(metricIdx, colIdx) {
      return colIdx === 0 && this.hasEntityNameRowHeaders;
    },
    isCellSelected(rowIdx, colIdx) {
      if (colIdx < 0) {
        this.$log.warn('colIdx < 0');
        return false;
      }
      if (this.selectedCells[rowIdx][colIdx] === undefined) {
        this.$log.warn('An undefined rowIdx, colIdx', rowIdx, colIdx);
      }
      return this.selectedCells[rowIdx][colIdx];
    },
    isCellHighlighted(rowIdx, colIdx) {
      return this.selectedHeaders[colIdx] || this.selectedRowHeaders[rowIdx];
    },
    isCellVerified(metricIdx) {
      const metric = this.metrics[metricIdx];
      if (metric.metricType === 'AKA') {
        // AKA cell always lives below a ENTITY_NAME cell.
        // If the ENTITY_NAME cell is verified, the aka cell is also verified.
        return this.metrics[metricIdx - 1].isVerified;
      }
      return metric.isVerified;
    },
    onCellSelected(rowIdx, colIdx) {
      this.$emit('cellSelected', rowIdx, colIdx);
    },
    onRowHeaderSelected(rowIdx, colIdx) {
      this.$emit('rowHeaderSelected', rowIdx, colIdx);
    },
    onToggleCellVerified(rowIdx, colIdx) {
      this.$emit('toggleCellVerified', rowIdx, colIdx);
    },
    onCellValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellValueUpdated', rowIdx, colIdx, newValue);
    },
    onCellCurrencyValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellCurrencyValueUpdated', rowIdx, colIdx, newValue);
    },
    onCellAkasValueUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellAkasUpdated', rowIdx, colIdx, newValue);
    },
    onSelectedEntityUpdated(rowIdx, colIdx, newValue) {
      this.$emit('cellSelectedEntityUpdated', rowIdx, colIdx, newValue);
    },
    onAddNewRowAt(rowIdx) {
      this.$emit('addNewRowAt', rowIdx);
    },
    onDeleteRowAt(rowIdx) {
      this.$emit('deleteRowAt', rowIdx);
    },
    onRenameEntity(rowIdx, colIdx, rowValue, metricFin, metricValidators) {
      this.$emit('renameEntityAt', rowIdx, colIdx, rowValue, metricFin, metricValidators);
    },
    onRemoveEntity(colIdx) {
      this.$emit('removeEntity', colIdx);
    },
  },
};
</script>
