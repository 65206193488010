<template>
  <currency-cell
    v-if="metricData.metricType === 'CURRENCY'"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :model-currency-value="metricData.datapoints[0].effectors.currency || ''"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :validators="metricData.validators"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
    @update:currencyModelValue="onCurrencyValueUpdated"
  />
  <percentage-cell
    v-else-if="metricData.metricType === 'PERCENTAGE'"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :validators="metricData.validators"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
  />
  <numeric-cell
    v-else-if="metricData.metricType === 'NUMERIC'"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :validators="metricData.validators"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
  />
  <date-cell
    v-else-if="metricData.metricType === 'DATE'"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :validators="metricData.validators"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
  />
  <aka-cell
    v-else-if="metricData.metricType === 'AKA'"
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :validators="metricData.validators"
    :entity-akas="existingAkas"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onAkasUpdated"
  />
  <entity-link-cell
    v-else-if="metricData.metricType === 'ENTITY_LINK'"
    :custom-classes="customClasses"
    :selected-entity="selectedEntity"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :show-confidence="showConfidence"
    :hide-verified-status="hideVerifiedStatus"
    :link-group-id-options="entityLinkOptions ?? []"
    :read-only-mode="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @selectedEntityUpdated="onSelectedEntityUpdated"
  />
  <text-cell
    v-else
    :custom-classes="customClasses"
    :model-value="modelValue"
    :is-cell-selected="isCellSelected"
    :is-cell-highlighted="isCellHighlighted"
    :is-verified="isVerified"
    :confidence="confidence"
    :show-confidence="showConfidence"
    :hide-verified-status="hideVerifiedStatus"
    :validators="metricData.validators"
    :is-read-only="readOnlyMode"
    @cellSelected="onCellSelected"
    @toggleCellVerified="onVerifyToggled"
    @update:modelValue="onValueUpdated"
  />
</template>
<script>
import { inject } from 'vue';
import clonedeep from 'lodash.clonedeep';
import { useToast } from 'vue-toastification';
import EntityLinkCell from '@/components/verify/cellTypes/EntityLinkCell.vue';
import NumericCell from '@/components/verify/cellTypes/NumericCell.vue';
import CurrencyCell from '@/components/verify/cellTypes/CurrencyCell.vue';
import TextCell from '@/components/verify/cellTypes/TextCell.vue';
import PercentageCell from '@/components/verify/cellTypes/PercentageCell.vue';
import DateCell from '@/components/verify/cellTypes/DateCell.vue';
import AkaCell from '@/components/verify/cellTypes/AkaCell.vue';
import { validateEntityName } from '@/store/helpers/request/validators/validateMetrics';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';

export default {
  components: {
    EntityLinkCell,
    NumericCell,
    AkaCell,
    DateCell,
    PercentageCell,
    TextCell,
    CurrencyCell,
  },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    entityLinkOptions: {
      type: Array,
      default: () => [],
    },
    metricData: {
      type: Object,
      required: true,
    },
    isCellHighlighted: {
      type: Boolean,
      default: false,
    },
    isCellSelected: {
      type: Boolean,
      required: true,
    },
    showConfidence: {
      type: Boolean,
      required: true,
    },
    hideVerifiedStatus: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['cellSelected', 'toggleCellVerified', 'cellValueUpdated', 'cellCurrencyValueUpdated', 'cellAkasUpdated', 'selectedEntityUpdated'],
  setup() {
    const readOnlyMode = inject('readOnlyMode', true);
    return { readOnlyMode };
  },
  data() {
    return {
      toast: useToast(),
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    confidence() {
      if (this.modelValue === null) {
        return 0;
      }
      return (this.isVerified && !this.enableUXRefresh) ? null : this.metricData.datapoints[0].confidence;
    },
    metricDisplay() {
      return this.metricData?.datapoints?.length ? this.metricData.datapoints[0].value : '-';
    },
    selectedEntity() {
      return this.metricData.linkedEntity;
    },
    modelValue() {
      const dp = this.metricData.datapoints[0];
      if (this.metricData.metricType === 'AKA') {
        return dp.effectors.akas ? clonedeep(dp.effectors.akas) : [];
      }

      return dp.value;
    },
    existingAkas() {
      if (this.metricData.metricType === 'AKA' && this.metricData.entityAkas?.length > 0) {
        return clonedeep(this.metricData.entityAkas);
      }
      return [];
    },
  },
  methods: {
    onCellSelected() {
      this.$emit('cellSelected');
    },
    onVerifyToggled() {
      this.$emit('toggleCellVerified');
    },
    onSelectedEntityUpdated(newValue) {
      this.$emit('selectedEntityUpdated', newValue);
    },
    onValueUpdated(newValue) {
      if (this.metricData.metricType === 'ENTITY_NAME') {
        try {
          validateEntityName(newValue, this.metricData.validators);
        } catch (e) {
          this.toast.error(e.message);
          return;
        }
      }
      this.$emit('cellValueUpdated', newValue);
    },
    onCurrencyValueUpdated(newValue) {
      this.$emit('cellCurrencyValueUpdated', newValue);
    },
    onAkasUpdated(newValue) {
      this.$emit('cellAkasUpdated', newValue);
    },
  },
};
</script>
