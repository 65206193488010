import isSet from '@/store/helpers/isSet';
import { logger } from '@/store/logger';

export default (annotation) => {
  if (isSet(annotation.nv) && isSet(annotation.nv.d) && annotation.nv.d.length) {
    return annotation.nv.d[0];
  }
  logger.warn('No normalised value found for annotation');
  return null;
};
