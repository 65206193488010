<template>
  <thead>
    <tr class="metric-table-column-headers">
      <metric-table-column-header
        v-for="(header, headerIdx) in columnHeaders"
        v-show="showColumnIndices[headerIdx]"
        :key="headerIdx"
        :custom-classes="headerCustomClasses(header, headerIdx)"
        :header-text="header.text"
        :header-description="header.description"
        :header-options="columnHeaderOptions[headerIdx]"
        :is-selected="selectedHeaders[headerIdx]"
        :is-verified="columnVerificationFlags[headerIdx]"
        :show-column-options="header.metricType === 'CURRENCY'"
        :can-show-verify-toggle="!((headerIdx === 0 && header.metricType === 'ENTITY_NAME') || header.metricType === 'AKA')"
        @headerSelected="onHeaderSelected(headerIdx)"
        @verifyToggled="onVerifyToggled(headerIdx)"
        @currencyChanged="onCurrencyChanged(headerIdx)"
        @increaseScale="onAdjustScale(headerIdx, 3)"
        @decreaseScale="onAdjustScale(headerIdx, -3)"
        @numericFormatChanged="onNumericFormatChanged(headerIdx)"
      />
    </tr>
  </thead>
</template>
<script>
import MetricTableColumnHeader from '@/components/verify/tableLayout/MetricTableColumnHeader.vue';

export default {
  components: { MetricTableColumnHeader },
  props: {
    columnHeaders: {
      type: Array,
      required: true,
    },
    columnHeaderOptions: {
      type: Array,
      required: true,
    },
    columnVerificationFlags: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
    showColumnIndices: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'headerSelected',
    'verifyHeaderToggled',
    'headerCurrencyChanged',
    'headerAdjustScale',
    'headerNumericFormatChanged',
  ],
  methods: {
    headerCustomClasses(header, headerIdx) {
      return {
        'sticky-top-left': headerIdx === 0 && header.metricType === 'ENTITY_NAME',
        'sticky-left': headerIdx === 0 && header.metricType === 'ENTITY_NAME',
      };
    },
    onHeaderSelected(headerIdx) {
      this.$emit('headerSelected', headerIdx);
    },
    onVerifyToggled(headerIdx) {
      this.$emit('verifyHeaderToggled', headerIdx);
    },
    onCurrencyChanged(headerIdx) {
      this.$emit('headerCurrencyChanged', headerIdx);
    },
    onAdjustScale(headerIdx, scale) {
      this.$emit('headerAdjustScale', headerIdx, scale);
    },
    onNumericFormatChanged(headerIdx) {
      this.$emit('headerNumericFormatChanged', headerIdx);
    },
  },
};
</script>
